import React, { useState, useEffect, useMemo } from "react";
import ExportApi from "../api/ExportApi";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import { toast } from "react-toastify";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
 
const Updatebulkprice = () => {
  const [seriesData, setSeriesData] = useState([]);
  const [editedData, setEditedData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filterloader, setFilterLoader] = useState(false);
  const [progress, setProgress] = useState(0);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });

  const fetchallSeries = async () => {
    setFilterLoader(true);
    try {
      const response = await ExportApi.getallseriesdata();
      console.log(response, "fetchallSeries123");
      setFilterLoader(false);
      setSeriesData(response.data.Data);
    } catch (error) {
      setFilterLoader(false);
      console.error("Error fetching admin series:", error);
    }
  };

  useEffect(() => {
    fetchallSeries();
  }, []);

 

  const handleEdit = (index, field, value) => {
    // Clone the sortedData and update the specific field
    const updatedSeriesData = [...sortedData];
    updatedSeriesData[index][field] = value;
  
    // Determine the edited item
    const editedItem = updatedSeriesData[index];
    const existingIndex = editedData.findIndex(
      (item) => item.productid === editedItem.productid
    );
  
    if (existingIndex !== -1) {
      // If item exists in editedData, update it
      const updatedEditedData = [...editedData];
      updatedEditedData[existingIndex] = editedItem;
      setEditedData(updatedEditedData);
    } else {
      // Add the new item to editedData
      setEditedData((prevData) => [...prevData, editedItem]);
    }
  };
  
  const filterOriginalData = (originalData, editedData) => {
    // Create a Set of product IDs from editedData for quick lookup
    const editedProductIds = new Set(editedData.map(item => item.productid));
  
    // Filter out items from originalData that are in editedData
    return originalData.filter(item => !editedProductIds.has(item.productid));
  };
  
  

  // const updateBulk = async () => {
  //   setLoading(true);
  //   try {
  //     // Filter out edited items from original data

  //     console.log(sortedData,"sortedData123");
      
  //     console.log(editedData,"editedData123");
       
  //     const filteredOriginalData = filterOriginalData(sortedData, editedData);
  
  //  console.log(filteredOriginalData,"filteredOriginalData123");
   
  //     // Concatenate editedData and filteredOriginalData into one array
  //     const combinedData = [...editedData, ...filteredOriginalData];
  
  //     // Prepare the payload
  //     const payload = {
  //       editedData: editedData,
  //       originalData:filteredOriginalData
  //     };
  //      console.log(payload,"payload123");
      
  
   
  //     const response = await ExportApi.Updatebulkproduct(combinedData);
  //     console.log(response, "response");
  
  //     if (response?.data?.message === "Product data updated successfully") {
  //       toast.success("Data updated successfully!");
  //       fetchallSeries(); // Fetch updated data
  //     } else if (response?.data?.status === false) {
  //       toast.error(response?.data?.message || "Error updating data");
  //     }
  //   } catch (error) {
  //     console.error(error, "bulkupdate");
  //     toast.error("An error occurred while updating data.");
  //   } finally {
  //     setLoading(false);
  //   }
  // };
  
  

  const BATCH_SIZE = 10;

  const updateBulk = async () => {
    setLoading(true);
    setProgress(0); // Initialize progress to 0
  
    try {
      // Filter out edited items from original data
      const filteredOriginalData = filterOriginalData(sortedData, editedData);
      const combinedData = [...editedData, ...filteredOriginalData];
      const totalBatches = Math.ceil(combinedData.length / BATCH_SIZE);
  
      for (let batchNumber = 0; batchNumber < totalBatches; batchNumber++) {
        const startIndex = batchNumber * BATCH_SIZE;
        const endIndex = startIndex + BATCH_SIZE;
        const batchData = combinedData.slice(startIndex, endIndex);
  
        // Send the batchData directly as the payload
        const response = await ExportApi.Updatebulkproduct(batchData);
  
        console.log(response, "response");
  
        if (response?.data?.message === "Product data updated successfully") {
          // toast.success(`Batch ${batchNumber + 1} updated successfully!`);
          setProgress(((batchNumber + 1) / totalBatches) * 100); // Update progress
        } else if (response?.data?.status === false) {
          toast.error(response?.data?.message || "Error updating data");
          break; // Stop processing further if an error occurs
        }
      }
  
      // Final message
      toast.success("All data updated successfully!");
      fetchallSeries(); // Fetch updated data
    } catch (error) {
      console.error(error, "bulkupdate");
      toast.error("An error occurred while updating data.");
    } finally {
      setLoading(false);
    }
  };
  
  

  

  const handleSort = (column) => {
    let direction = "ascending";
    console.log(sortConfig.key, "sortConfig.ke111");
    if (sortConfig.key === column && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key: column, direction });
  };
  const sortedData = useMemo(() => {
    if (sortConfig.key !== null) {
      return [...seriesData].sort((a, b) => {
        let aValue = a[sortConfig.key];
        let bValue = b[sortConfig.key];
  
        // Normalize values
        aValue = aValue === 0 || aValue === null || aValue === undefined ? "" : aValue;
        bValue = bValue === 0 || bValue === null || bValue === undefined ? "" : bValue;
  
        //console.log(`Comparing ${aValue} and ${bValue}`); 
  
        // Handle numeric sorting if both are numbers
        if (!isNaN(aValue) && !isNaN(bValue)) {
          aValue = Number(aValue);
          bValue = Number(bValue);
          return sortConfig.direction === "ascending"
            ? aValue - bValue
            : bValue - aValue;
        }
  
        // Handle string sorting with embedded numbers
        if (typeof aValue === "string" && typeof bValue === "string") {
          // Extract numeric part and compare
          const extractNumber = (str) => {
            const match = str.match(/\d+/);
            return match ? parseInt(match[0], 10) : 0;
          };
  
          const extractAlpha = (str) => {
            return str.replace(/\d+/g, '').trim();
          };
  
          const aNumber = extractNumber(aValue);
          const bNumber = extractNumber(bValue);
  
          const aAlpha = extractAlpha(aValue);
          const bAlpha = extractAlpha(bValue);
  
          if (aAlpha === bAlpha) {
            return sortConfig.direction === "ascending"
              ? aNumber - bNumber
              : bNumber - aNumber;
          }
  
          return sortConfig.direction === "ascending"
            ? aAlpha.localeCompare(bAlpha)
            : bAlpha.localeCompare(aAlpha);
        }
  
        // Default case for non-comparable values
        return 0;
      });
    }
    return seriesData;
  }, [seriesData, sortConfig]);
  
  
  
  

  return (
    <div>
      <h2>Update Bulk Price</h2>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell
                style={{ fontWeight: "bold" }}
                onClick={() => handleSort("series")}
              >
                GPU Series
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  className="bi bi-chevron-expand"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M3.646 9.146a.5.5 0 0 1 .708 0L8 12.793l3.646-3.647a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 0-.708zm0-2.292a.5.5 0 0 0 .708 0L8 3.207l3.646 3.647a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 0 0 0 .708z"
                  />
                </svg>
              </TableCell>

              <TableCell
                style={{ fontWeight: "bold" }}
                onClick={() => handleSort("benchmark_score")}
              >
                Benchmark
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  className="bi bi-chevron-expand"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M3.646 9.146a.5.5 0 0 1 .708 0L8 12.793l3.646-3.647a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 0-.708zm0-2.292a.5.5 0 0 0 .708 0L8 3.207l3.646 3.647a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 0 0 0 .708z"
                  />
                </svg>
              </TableCell>

              <TableCell
                style={{ fontWeight: "bold" }}
                onClick={() => handleSort("used_house_ask")}
              >
                House Ask Used
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  className="bi bi-chevron-expand"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M3.646 9.146a.5.5 0 0 1 .708 0L8 12.793l3.646-3.647a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 0-.708zm0-2.292a.5.5 0 0 0 .708 0L8 3.207l3.646 3.647a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 0 0 0 .708z"
                  />
                </svg>
              </TableCell>

              <TableCell
                style={{ fontWeight: "bold" }}
                onClick={() => handleSort("used_house_bid")}
              >
                House Bid Used
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  className="bi bi-chevron-expand"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M3.646 9.146a.5.5 0 0 1 .708 0L8 12.793l3.646-3.647a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 0-.708zm0-2.292a.5.5 0 0 0 .708 0L8 3.207l3.646 3.647a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 0 0 0 .708z"
                  />
                </svg>
              </TableCell>

              <TableCell
                style={{ fontWeight: "bold" }}
                onClick={() => handleSort("new_second_hand_house_ask")}
              >
                House Ask New
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  className="bi bi-chevron-expand"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M3.646 9.146a.5.5 0 0 1 .708 0L8 12.793l3.646-3.647a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 0-.708zm0-2.292a.5.5 0 0 0 .708 0L8 3.207l3.646 3.647a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 0 0 0 .708z"
                  />
                </svg>
              </TableCell>

              <TableCell
                style={{ fontWeight: "bold" }}
                onClick={() => handleSort("new_second_hand_house_bid")}
              >
                House Bid New
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  className="bi bi-chevron-expand"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M3.646 9.146a.5.5 0 0 1 .708 0L8 12.793l3.646-3.647a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 0-.708zm0-2.292a.5.5 0 0 0 .708 0L8 3.207l3.646 3.647a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 0 0 0 .708z"
                  />
                </svg>
              </TableCell>
            </TableRow>
          </TableHead>

          {/* <TableBody>
            {filterloader ? (
              <div>
                <h5>
                  <b>Loading...</b>
                </h5>
              </div>
            ) : (
              sortedData.map((series, index) => (
                <TableRow key={index}>
                  <TableCell>{series.series || ""}</TableCell>
                  <TableCell>
                    <input
                      type="number"
                      value={series.benchmark_score || ""}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (/^\d*$/.test(value)) {
                          handleEdit(
                            index,
                            "benchmark_score",
                            value === "" ? null : Number(value)
                          );
                        }
                      }}
                      className="bulktablerow"
                    />
                  </TableCell>
                  <TableCell>
                    <input
                      type="number"
                      value={series.used_house_ask || ""}
                      onChange={(e) => {
                        const value = e.target.value;
                        // Ensure the value is a valid number or empty
                        if (/^\d*$/.test(value)) {
                          handleEdit(
                            index,
                            "used_house_ask",
                            value === "" ? null : Number(value)
                          );
                        }
                      }}
                      className="bulktablerow"
                    />
                  </TableCell>
                  <TableCell>
                    <input
                      type="number"
                      value={series.used_house_bid || ""}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (/^\d*$/.test(value)) {
                          handleEdit(
                            index,
                            "used_house_bid",
                            value === "" ? null : Number(value)
                          );
                        }
                      }}
                      className="bulktablerow"
                    />
                  </TableCell>
                  <TableCell>
                    <input
                      type="number"
                      value={series.new_second_hand_house_ask || ""}
                      onChange={(e) => {
                        const value = e.target.value;
                        // Ensure the value is a valid number or empty
                        if (/^\d*$/.test(value)) {
                          handleEdit(
                            index,
                            "new_second_hand_house_ask",
                            value === "" ? null : Number(value)
                          );
                        }
                      }}
                      className="bulktablerow"
                    />
                  </TableCell>
                  <TableCell>
                    <input
                      type="number"
                      value={series.new_second_hand_house_bid || ""}
                      onChange={(e) => {
                        const value = e.target.value;
                        // Ensure the value is a valid number or empty
                        if (/^\d*$/.test(value)) {
                          handleEdit(
                            index,
                            "new_second_hand_house_bid",
                            value === "" ? null : Number(value)
                          );
                        }
                      }}
                      className="bulktablerow"
                    />
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody> */}

<TableBody>
  {filterloader ? (
    <div>
      <h5><b>Loading...</b></h5>
    </div>
  ) : (
    sortedData.map((series, index) => (
      <TableRow key={index}>
        <TableCell>{series.series || ""}</TableCell>
        <TableCell>
          <input
            type="number"
            value={series.benchmark_score || ""}
            onChange={(e) => {
              const value = e.target.value;
              if (/^\d*$/.test(value)) {
                handleEdit(index, "benchmark_score", value === "" ? null : Number(value));
              }
            }}
            className="bulktablerow"
          />
        </TableCell>
        <TableCell>
          <input
            type="number"
            value={series.used_house_ask || ""}
            onChange={(e) => {
              const value = e.target.value;
              if (/^\d*$/.test(value)) {
                handleEdit(index, "used_house_ask", value === "" ? null : Number(value));
              }
            }}
            className="bulktablerow"
          />
        </TableCell>
        <TableCell>
          <input
            type="number"
            value={series.used_house_bid || ""}
            onChange={(e) => {
              const value = e.target.value;
              if (/^\d*$/.test(value)) {
                handleEdit(index, "used_house_bid", value === "" ? null : Number(value));
              }
            }}
            className="bulktablerow"
          />
        </TableCell>
        <TableCell>
          <input
            type="number"
            value={series.new_second_hand_house_ask || ""}
            onChange={(e) => {
              const value = e.target.value;
              if (/^\d*$/.test(value)) {
                handleEdit(index, "new_second_hand_house_ask", value === "" ? null : Number(value));
              }
            }}
            className="bulktablerow"
          />
        </TableCell>
        <TableCell>
          <input
            type="number"
            value={series.new_second_hand_house_bid || ""}
            onChange={(e) => {
              const value = e.target.value;
              if (/^\d*$/.test(value)) {
                handleEdit(index, "new_second_hand_house_bid", value === "" ? null : Number(value));
              }
            }}
            className="bulktablerow"
          />
        </TableCell>
      </TableRow>
    ))
  )}
</TableBody>

        </Table>

        {filterloader ? (
          ""
        ) : (
          <div className="d-flex align-items-center justify-content-center">
            <Button
              variant="contained"
              color="primary"
              onClick={updateBulk}
              disabled={loading}
            >
              {loading ? "Updating..." : "Update"}
            </Button>
            {loading && (
        <div style={{ display: 'flex', alignItems: 'center'}}>
          <CircularProgress variant="determinate" value={progress} />
          <Typography variant="body2" color="textSecondary">
            {`${Math.round(progress)}%`}
          </Typography>
        </div>
      )}
            &nbsp; &nbsp;
          </div>
        )}
        
      </TableContainer>
      <br></br>
      <br></br>
      <br></br>
    </div>
  );
};

export default Updatebulkprice;
