import React, { useEffect, useState, useCallback, useRef } from "react";
import {
    Form,
    Button
} from "react-bootstrap";
import { IconButton } from "@mui/material";
import ExportApi from "../../../api/ExportApi";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from 'react-hook-form';
import { CKEditor } from "@ckeditor/ckeditor5-react";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { ClassicEditor,Font, Bold,Italic ,Link ,List ,
    BlockQuote ,Heading, Image ,MediaEmbed 
    ,Table as Tabelck ,TableToolbar ,Undo
  } from 'ckeditor5';
import "../HelpManagement.css"



export default function CreateTopic() {


    const { register, handleSubmit, setValue, control, reset, formState: { errors }, } = useForm();

    const [count, setCount] = useState();
    const [categoryData, setCategoryData] = useState();
    const [fileError, setFileError] = useState('');
    const [loader, setLoader] = useState(false);
    const navigate = useNavigate();


    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const fileSizeMB = file.size / (1024 * 1024); // Convert file size to MB
            const allowedExtensions = ['jpg', 'jpeg', 'png'];
            const fileExtension = file.name.split('.').pop().toLowerCase();
            
            if (!allowedExtensions.includes(fileExtension)) {
                setFileError('Only JPG, JPEG, and PNG files are allowed');
                setValue('file', null); // Reset file value in the form state
            } else if (fileSizeMB > 1) {
                setFileError('File size should not exceed 1 MB');
                setValue('file', null); // Reset file value in the form state
            } else {
                setFileError(''); // Clear any previous error
                setValue('file', file); // Update form state with the selected file
            }
        }
    };
    
    const onSubmit = (data) => {
        console.log(data);
        setLoader(true);
        const isMain = data?.main;
        if (isMain && !data?.file) {
            setFileError("Topic icon is required");
            setLoader(false);
            console.log(isMain);
            return
        } else {
            setFileError('');
            console.log(isMain);
        }
        const formData = new FormData();
        formData.append('name', data?.name);
        formData.append('short_description', data?.short_description);
        formData.append('description', data?.description);
        if (data?.file) {
            formData.append('image', data.file);
        }
        formData.append('categoryId', data?.categoryId);
        formData.append('main', data?.main);
        ExportApi.addHelpTopic(formData).then((resp) => {
            console.log(resp?.data);
            const data = resp?.data;
            if (data?.status == 201) {
                setLoader(false);
                toast.success(data?.message)
                reset();
                setCount(1);
                navigate('/admin/topic-list');
            } else if (data?.status == 203) {
                setLoader(false);
                toast.error(data?.message)
                setCount(1)
            } else {
                setLoader(false);
                toast.error(data?.message)
                setCount(1)
            }
        }).catch((err) => console.log(err))
        setCount(1)
    };

    useEffect(() => {
        getCategoryData()
    }, [])

    const getCategoryData = () => {
        ExportApi.getHelpData('').then((resp) => {
            console.log(resp?.data);
            const data = resp?.data;
            if (data?.status == 201) {
                console.log(data?.detail);
                setCategoryData(data?.detail);
                setCount(1)
            } else {
                toast.error(data?.message)
                setCount(1)
            }
        }).catch((err) => {
            console.log(err)
        })
        setCount(1)
    }

    return (
        <>
            {count == 0 ?
                <div className="loader-icon" style={{ marginBlock: "80px" }}>
                    <i className="fa-solid fa-spinner fa-spin-pulse"></i>
                </div>
                :
                <Form onSubmit={handleSubmit(onSubmit)} className="py-5">
                    <h2 className="mb-4">Create Topic</h2>
                    <Form.Group controlId="name" className="mb-4">
                        <Form.Label>Topic Name</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter Topic name"
                            {...register('name', { required: 'Topic Name is required' })}
                        />
                        {errors.name && <p className="form-error">{errors.name.message}</p>}
                    </Form.Group>

                    <Form.Group controlId="name" className="mb-4">
                        <Form.Label>Short Description</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter Short description"
                            {...register('short_description', { required: 'Topic Short description is required' })}
                        />
                        {errors.short_description && <p className="form-error">{errors.short_description.message}</p>}
                    </Form.Group>

                    <Form.Label>Topic Body Description</Form.Label>
                    <Controller
                        name="description"
                        control={control}
                        defaultValue="" // Set the initial value here if needed
                        rules={{ required: 'Topic Description is required' }} // Add rules if needed
                        render={({ field }) => (
                            <CKEditor
                            editor={ClassicEditor}
                            config={{
                                plugins: [
                                    Font, Bold, Italic, Link, List, BlockQuote, Heading, Image, 
                                     MediaEmbed, Tabelck, TableToolbar, Undo
                                  ],
                              toolbar: {
                                items: [
                                  'heading',
                                  '|',
                                  'bold',
                                  'italic',
                                  'link',
                                  'bulletedList',
                                  'numberedList',
                                  'blockQuote',
                                  'indent',
                                  'outdent', // Ensure these are included
                                  '|',
                                  'undo',
                                  'redo',
                                  'fontFamily',
                                ]
                              },
                              indentBlock: {
                                offset: 1,
                                unit: 'em'
                              },
                              fontFamily: {
                                options: [
                                 
                                  'Helvetica',
                                  'Open Sans'
                                ]
                            },
                            }}
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    setValue('description', data); // Update the form value
                                    field.onChange(data); // Update the field value
                                }}
                            />
                        )}
                    />
                    {errors.description && <p className="form-error">{errors.description.message}</p>}

                    <Form.Group controlId="file" className="my-4">
                        <Form.Label>Topic Icon</Form.Label>
                        <Form.Control
                            type="file"
                            name="file"
                            accept=".jpg, .jpeg, .png"
                            // onChange={(e) => {

                            //     const file = e.target.files[0];
                            //     setValue('file', file);
                            // }}
                            onChange={handleFileChange}
                        />
                        {fileError != '' ? <p className="form-error">{fileError}</p> : ""}
                    </Form.Group>

                    <Form.Group controlId="name" className="d-flex flex-column category-select">
                        <Form.Label>Select Category</Form.Label>
                        <Controller
                            name="categoryId"
                            control={control}
                            render={({ field }) => (
                                <select {...field}>
                                    <option value="">Select an option</option>
                                    {categoryData?.length > 0 ? categoryData.map((item) => (
                                        <option key={item?._id} value={item?._id}>{item?.name}</option>
                                    )) : ""}
                                </select>
                            )}
                        />
                        {errors.categoryId && <p className="form-error">{errors.categoryId.message}</p>}
                    </Form.Group>

                    <Form.Group controlId="name" className="mt-4">
                        <Controller
                            name="main"  
                            control={control}
                            defaultValue={false}  
                            render={({ field }) => (
                                <input
                                    type="checkbox"
                                    {...field}
                                />
                            )}
                        />
                        <label htmlFor="main" className="ms-3">Main topic</label>
                        {errors.main && <p>{errors.main.message}</p>}
                    </Form.Group>

                    <Button variant="primary" type="submit" className="mt-5">
                        {/* Submit */}
                        {loader ? "Submiting" : "Submit"}
                    </Button>
                </Form>
            }
        </>

    );
};