import React, { useRef, useEffect, useState, useCallback } from "react";
import Footer from "../../components/footer";
import Header from "../../components/header";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import ExportApi from "../../api/ExportApi";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import _ from "lodash";

const filterHtmlContent = (html) => {
  // Replace all HTML tags and non-breaking spaces (&nbsp;) with an empty string
  return html
    .replace(/<[^>]*>/g, "") // Remove all HTML tags
    .replace(/&nbsp;/g, ""); // Remove all &nbsp; entities
};

function List() {
  const [featurednews, setFeaturedNews] = useState([]);
  const [newfeatured, setNewFeatured] = useState([]);
  const [unfeaturednews, setUnFeaturedNews] = useState([]);
  const [loading, setLoading] = useState(false);
  const [clickCount, setClickCount] = useState(0);
  const [keyword, setKeyword] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [searchloading, setSearchLoading] = useState(false);
  const [searchPerformed, setSearchPerformed] = useState(false);
  const [showdata, setShowData] = useState(false);

  const navigate = useNavigate();
  const searchRef = useRef(null);

  const fetchNewsList = async () => {
    try {
      setLoading(true);
      const response = await ExportApi.getAllnewslist();
      console.log(response, "response3211neww");

      const filteredNewsList = response.data.data
        .filter((news) => news.featured)
        .sort((a, b) => b.totalCount - a.totalCount);

      const topItem = filteredNewsList[0];

      const remainingItems = filteredNewsList
        .slice(1, 5)
        .map((news, index) => ({
          ...news,
          index: index + 1,
        }));

      const unfilteredNewsList = response?.data?.data
        .filter((news) => news.unfeatured)
        .sort((a, b) => b.totalCount - a.totalCount)
        .slice(0, 6);

      console.log(topItem, "topItem643");
      console.log(remainingItems, "remainingItems00099");

      setLoading(false);
      setFeaturedNews(topItem);
      setNewFeatured(remainingItems);
      setUnFeaturedNews(unfilteredNewsList);
    } catch (error) {
      console.error("Error fetching news list:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchNewsList();
  }, []);

  //   useEffect(() => {
  //     const updatePageCount = async () => {
  //       try {
  //         // Get the current page count from localStorage
  //         const count = localStorage.getItem("pageCount");
  //         // Determine the new count
  //         const newCount = count ? parseInt(count) + 1 : 1;
  //         // Save the new count back to localStorage
  //         localStorage.setItem("pageCount", newCount);

  //         // Send the updated count to the API
  //         const response = await ExportApi.totalcount({ count: newCount });

  //         console.log("Count saved successfully:", response.data);
  //       } catch (error) {
  //         console.error("Error saving count:", error);
  //       }
  //     };

  //     updatePageCount();
  //   }, []);

  let startTime = new Date();

  const handleClick = async (newsItemId) => {
    try {
      navigate(`/news/${newsItemId}`);
      const endTime = new Date();
      const timeDifference = endTime - startTime;
      startTime = endTime; // Reset start time for the next click

      const averageTime = timeDifference / (clickCount + 1);
      const averageTimeInSeconds = averageTime / 1000;

      const data = {
        averageTime: parseFloat(averageTimeInSeconds.toFixed(2)),
        totalCount: clickCount + 1,
      };

      const response = await ExportApi.totalcount(newsItemId, data);

      if (response.ok) {
        console.log("Data posted successfully");
      } else {
        console.error("Failed to post data:", response.statusText);
      }

      setClickCount((prevCount) => prevCount + 1);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // const debounceSearch = useCallback(
  //   _.debounce(async (search) => {
  //     if (!search) {
  //       setSearchResults([]);
  //       setSearchPerformed(false);
  //       setSearchLoading(false);
  //       return;
  //     }
  //     setSearchLoading(true);
  //     setSearchPerformed(true);
  //     try {
  //       const response = await ExportApi.newssearch({ keyword: search });
  //       setSearchResults(response.data.data);
  //       setSearchLoading(false);
  //     } catch (error) {
  //       console.error("Error searching for news:", error);
  //       setSearchLoading(false);
  //     }
  //   }, 300),
  //   []
  // );

  const debounceSearch = useCallback(
    _.debounce(async (search) => {
      if (search.trim().length === 0) {
        setSearchResults([]);
        setSearchLoading(false);
        return;
      }
      setSearchLoading(true);
      try {
        const response = await ExportApi.newssearch({ keyword: search });
        setShowData(true);
        console.log("API response:", response.data); // Check the response structure
        setSearchResults(response.data.data);
      } catch (error) {
        console.error("Error searching for news:", error);
        setSearchResults([]);
      } finally {
        setSearchLoading(false);
      }
    }, 300),
    []
  );
  const handleSearch = (e) => {
    const search = e.target.value;
    if (!search) {
      console.log("hit this upp");
      setShowData(false);
    }
    setKeyword(search);
    debounceSearch(search);
    setShowData(false);
    console.log(searchResults, "searchResults123");
  };

  console.log(showdata, "showdata6666");

  const handleFocus = () => {
    setSearchPerformed(true);

    if (searchResults?.length > 0) {
      setShowData(true);
    } else {
      setShowData(false);
    }
  };

  const handleClear = () => {
    setKeyword("");
    setSearchResults([]);
    setSearchPerformed(false);
  };

  const handleNewClear = () => {
    //setKeyword('');
    setSearchResults([]);
    setSearchPerformed(false);
  };

  const handleClickOutside = (event) => {
    if (searchRef.current && !searchRef.current.contains(event.target)) {
      // handleNewClear();
      setShowData(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  console.log(unfeaturednews, "unfeaturednews6545");
  return (
    <>
      <Header />

      {loading ? (
        <div className="loader-icon" style={{ marginBlock: "80px" }}>
          <i className="fa-solid fa-spinner fa-spin-pulse"></i>
        </div>
      ) : (
        <>
          <div
            className="search-bar py-5"
            style={{ background: "#f9fafb" }}
            ref={searchRef}
          >
            <Container>
              <Row>
                <Col>
                  <div className="search">
                    <div className="input-group">
                      <span className="input-group-text" id="basic-addon1">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          className="bi bi-search"
                          viewBox="0 0 16 16"
                        >
                          <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"></path>
                        </svg>
                      </span>
                      <input
                        placeholder="Search For News"
                        autoComplete="false"
                        type="text"
                        className="form-control"
                        value={keyword}
                        onChange={handleSearch}
                        onFocus={handleFocus}
                      />
                      {keyword && (
                        <div className="custom_svgCross">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="26"
                            height="26"
                            fill="#000"
                            className="bi bi-x"
                            viewBox="0 0 16 16"
                            onClick={handleClear}
                          >
                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                          </svg>
                        </div>
                      )}
                    </div>
                    {/* {searchloading ? (
                      <>
                        <div className="custom-news w-100">
                          <div
                            className="loader-icon"
                            style={{ marginBlock: "20px" }}
                          >
                            <i className="fa-solid fa-spinner fa-spin"></i>
                          </div>
                        </div>
                      </>
                    ) : searchPerformed && searchResults.length === 0 ? ( // Show message only if search was performed
                      <div className="custom-news w-100">
                        <p style={{ textAlign: "center" }}>No results found</p>
                      </div>
                    ) : (
                      searchResults.length > 0 && (
                        <div className="custom-news">
                          {searchResults.map((newsItem) => (
                            <div className="custom-newsbar" key={newsItem?._id}>
                              <img
                                src={newsItem?.image}
                                alt={newsItem?.heading}
                                style={{
                                  width: "100px",
                                  height: "100px",
                                  borderRadius: "5px",
                                }}
                                onClick={() => handleClick(newsItem?._id)}
                              />
                              <div
                                onClick={() => handleClick(newsItem?._id)}
                                style={{ cursor: "pointer" }}
                              >
                                <h3>{newsItem?.heading}</h3>
                                <p style={{wordBreak:"break-all"}}>
                                  {filterHtmlContent(
                                    newsItem?.description || ""
                                  )}
                                </p>
                              </div>
                            </div>
                          ))}
                        </div>
                      )
                    )} */}
                    {showdata && (
                      <>
                        {searchloading ? (
                          <div className="custom-news w-100">
                            <div
                              className="loader-icon"
                              style={{ marginBlock: "20px" }}
                            >
                              <i className="fa-solid fa-spinner fa-spin"></i>
                            </div>
                          </div>
                        ) : searchPerformed && searchResults.length === 0 ? (
                          // Show message only if search was performed
                          <div className="custom-news w-100">
                            <p style={{ textAlign: "center" }}>
                              No results found
                            </p>
                          </div>
                        ) : (
                          searchResults.length > 0 && (
                            <div className="custom-news">
                              {searchResults.map((newsItem) => (
                                <div
                                  className="custom-newsbar"
                                  key={newsItem?._id}
                                >
                                  <img
                                    src={newsItem?.image}
                                    alt={newsItem?.heading}
                                    style={{
                                      width: "100px",
                                      height: "100px",
                                      borderRadius: "5px",
                                    }}
                                    onClick={() => handleClick(newsItem?._id)}
                                  />
                                  <div
                                    onClick={() => handleClick(newsItem?._id)}
                                    style={{ cursor: "pointer" }}
                                  >
                                    <h3>{newsItem?.heading}</h3>
                                    <p style={{ wordBreak: "break-all" }}>
                                      {filterHtmlContent(
                                        newsItem?.description || ""
                                      )}
                                    </p>
                                  </div>
                                </div>
                              ))}
                            </div>
                          )
                        )}
                      </>
                    )}
                  </div>
                </Col>
              </Row>
            </Container>
          </div>

          <div className="pb-5 pt-2" style={{ background: "#f9fafb" }}>
            <Container>
              {featurednews ? (
                <>
                  <Row className="d-flex justify-content-center flex-wrap align-items-center p-3 bg-white grid-list mb-5">
                    <Col xs={12} md={6} className="ps-0">
                      <div
                        className="news-grid"
                        onClick={() => handleClick(featurednews?._id)}
                      >
                        <img
                          className="w-100"
                          style={{ width: "300px", height: "300px" }}
                          src={featurednews?.image}
                          alt={featurednews?.heading}
                        />
                      </div>
                    </Col>
                    <Col xs={12} md={6} className="pe-0">
                      <div
                        className="news-grid my-3 me-5"
                        onClick={() => handleClick(featurednews?._id)}
                      >
                        <p className="New-category">
                          {featurednews?.categories?.[0]?.name}
                        </p>
                        <h3 className="mb-3 text-dark fw-bold fs-4">
                          {featurednews?.heading}
                        </h3>
                        <p
                          className="fs-6 fw-normal"
                          dangerouslySetInnerHTML={{
                            __html: filterHtmlContent(
                              featurednews?.description || ""
                            ),
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                </>
              ) : (
                ""
              )}

              <Row className="mb-5">
                {newfeatured.length > 0
                  ? newfeatured.map((newsItem, index) => (
                      <Col key={index} xs={12} md={6}>
                        <div className="news-grid-box">
                          <div
                            className="news-grid"
                            onClick={() => handleClick(newsItem?._id)}
                          >
                            <img
                              className="w-100"
                              style={{ width: "200px", height: "200px" }}
                              src={newsItem?.image}
                              alt={newsItem?.heading}
                            />
                          </div>
                          <div
                            className="news-grid"
                            onClick={() => handleClick(newsItem?._id)}
                          >
                            <p className="New-category mb-2">
                              {newsItem?.categories[0]?.name}
                            </p>
                            <h3 className="mb-3 text-dark fw-bold fs-5">
                              {newsItem?.heading}
                            </h3>
                          </div>
                        </div>
                      </Col>
                    ))
                  : ""}

                 
              </Row>
              {/* <Row>   {newfeatured?.length === 4 ? (
                  <Link to="/news" className="all-post-link">
                    <button className="all-post-btn">Browse all posts</button>
                  </Link>
                ) : (
                  ""
                )}</Row> */}
             
            </Container>

            <div className="bg-white py-5">
              <Container>
                <Row className="mt-3">
                  {unfeaturednews?.length > 0
                    ? unfeaturednews?.map((newsItem, index) => (
                        <Col key={index} xs={12} md={4} className="mb-3">
                          <div
                            className="news-grid"
                            onClick={() => handleClick(newsItem?._id)}
                          >
                            <img
                              className="w-100 mb-3"
                              style={{ width: "300px", height: "300px" }}
                              src={newsItem?.image}
                              alt={newsItem?.heading}
                            />
                            <h3 className="mb-3 text-dark fw-bold fs-5">
                              {newsItem?.heading}
                            </h3>
                            <p
                              className="fs-6 fw-normal"
                              dangerouslySetInnerHTML={{
                                __html: filterHtmlContent(
                                  newsItem?.description || ""
                                ),
                              }}
                            />
                          </div>
                        </Col>
                      ))
                    : ""}
                  {unfeaturednews?.length === 6 ? (
                    <Link to="/news" className="all-post-link">
                      <button className="all-post-btn">Browse all posts</button>
                    </Link>
                  ) : (
                    ""
                  )}
                </Row>
              </Container>
            </div>
          </div>
        </>
      )}

      <Footer />
    </>
  );
}

export default List;
