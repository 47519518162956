import { useLocation, useNavigate } from "react-router-dom";
import ExportApi from "../api/ExportApi";
import Signup from "./Signup";
import ForgotPassword from "./ForgotPassword";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button } from "react-bootstrap";
import React, { useState } from "react";
import { InputOTP } from "antd-input-otp";
import { MdOutlineKeyboardArrowLeft } from "react-icons/md";

function Login(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [statusUSER, setStatusUSER] = useState(false);
  const [statusAdmin, setStatusAdmin] = useState(false);
  const [error, setError] = useState(false);
  const [stap, setStap] = useState(true);
  const [forgot, setForgot] = useState(false);
  const [signup, setSignup] = useState(false);
  const [passwordStatus, setPasswordStatus] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [showQRModal, setShowQRModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [secret, setSecret] = useState("");

  const [userid, setUserid] = useState();

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "username") {
      setUsername(value);
    } else if (name === "password") {
      setPassword(value);
    }
  };

  const handleSignup = () => {
    setStap(false);
    setSignup(true);
    setForgot(false);
  };

  const handleLogin = () => {
    setStap(true);
    setSignup(false);
    setForgot(false);
  };

  const handleForgotPassword = () => {
    setStap(false);
    setSignup(false);
    setForgot(true);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const usertoken = localStorage.getItem("tokenuser");
    const newData = JSON.parse(usertoken);

    const admintoken = localStorage.getItem("admin");
    const adminData = JSON.parse(admintoken);

    setButtonLoader(true);
    if (newData) {
      toast.error("Another Account Already Logged In");
      setTimeout(() => {
        navigate("/profile/" + newData.id);
      }, 2000);

      setButtonLoader(false);
      return;
    }

    if (adminData) {
      toast.error("Admin Account Already Logged In");
      setTimeout(() => {
        navigate("/orderView");
      }, 2000);
      setButtonLoader(false);
      return;
    }

    if (username && password) {
      try {
        const resp = await ExportApi.UserLogin(username, password);
        console.log(resp, "UserLogin1");

        if (resp?.data?.message == "Login successful") {
          setError(true);
          setStap(false);

          localStorage.setItem(
            "favourite",
            JSON.stringify(resp.data.data.favourite)
          );
          console.log("resp", resp);
          localStorage.setItem("token", resp.data.token);

          if (resp.data.data.role === "user") {
            setButtonLoader(false);
            toast.success(resp.data.data.Message);
            const loginTime = new Date().getTime();
            localStorage.setItem("loginTime", loginTime);
            localStorage.setItem("tokenuser", JSON.stringify(resp.data.data));
            let data = resp.data.data.shipping_address.street;
            localStorage.setItem("useraddress", JSON.stringify(data));
            //console.log(props.modalClose, resp.data.Data);
            if (location.pathname === "/") {
              navigate("/profile/" + resp.data.data.id);
              // window.location.href="/profile/" + resp.data.data.id
              props.modal();
            } else {
              navigate(location.href);
              props.modal();
            }
          } else {
            setButtonLoader(false);
            localStorage.setItem("admin", JSON.stringify(resp.data.data));
            const loginTime = new Date().getTime();
            localStorage.setItem("loginTime", loginTime);

            setStatusAdmin(true);

            navigate("/productlist");
          }
        } else if (
          resp?.data?.message === "2FA required. OTP sent successfully."
        ) {
          console.log(resp, "2faenabled");
          setError(true);
          setStap(false);

          setSecret(resp?.data?.secret);
          setShowQRModal(true);
          setUserid(resp?.data?.userId);
        } else {
          toast.error("Email and Password don't match");
          // this.setState({ error: "Email and password don't match" });
          setButtonLoader(false);
        }
      } catch (err) {
        console.error(err);
      }
    }
  };

  return (
    <div>
      <ToastContainer />
      {!showQRModal && stap ? (
        <>
          <div>
            <h4 className="text-center cursor-pointer  ">Login</h4>
          </div>
          <form
            className="form w-100 p-0 custom-logindata"
            onSubmit={handleSubmit}
          >
            <div className="input-container w-100">
              <label className="label fs-5 mb-2">Username: </label>
              <input
                type="text"
                name="username"
                className="input form-control"
                placeholder="Username"
                value={username}
                required
                onChange={handleChange}
              />
            </div>
            <div className="input-container w-100">
              <label className="label fs-5 mb-2">Password: </label>
              <div className="inner_part_icon">
                <input
                  type={passwordStatus ? "text" : "password"}
                  name="password"
                  className="input form-control mb-3"
                  placeholder="Password "
                  value={password}
                  required
                  onChange={handleChange}
                />
                {passwordStatus ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-eye-slash-fill position-absolute eye_icon"
                    viewBox="0 0 16 16"
                    onClick={() => setPasswordStatus(!passwordStatus)}
                  >
                    {/* SVG paths */}
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="19"
                    height="19"
                    fill="currentColor"
                    className="bi bi-eye-fill position-absolute eye_icon"
                    viewBox="0 0 16 16"
                    onClick={() => setPasswordStatus(!passwordStatus)}
                  >
                    {/* SVG paths */}
                  </svg>
                )}
              </div>
            </div>
            <div className="forgot_password">
              <Button
                className="custom-forgot float-right border-0 bg-none text-dark"
                onClick={handleForgotPassword}
              >
                Forgot Password?
              </Button>
            </div>
            <button
              type="submit"
              className=" d-block  custom-login"
              id="login-btn"
              disabled={buttonLoader}
            >
              {buttonLoader ? "Please Wait..." : "Login"}
            </button>
          </form>
        </>
      ) : signup ? (
        <Signup modal={props.modal} navigate={props.navigate} />
      ) : forgot ? (
        <ForgotPassword modal={props.modal} />
      ) : null}

      {!showQRModal && stap ? (
        <button
          className="d-block mb-3 custom-sign"
          id="login-btn"
          onClick={handleSignup}
        >
          Sign Up
        </button>
      ) : signup ? (
        <button
          className="d-block mb-3 custom-sign"
          id="login-btn"
          onClick={handleLogin}
        >
          Login
        </button>
      ) : forgot ? (
        // <button
        //   className="d-block mb-3 custom-sign"
        //   id="login-btn"
        //   onClick={handleLogin}
        // >
        //   Login
        // </button>
        <p className="text-center cursor-pointer" style={{cursor:"pointer"}} onClick={handleLogin}>
        <MdOutlineKeyboardArrowLeft className="fs-3"/>Back to login</p>
      ) : null}
    </div>
  );
}

export default Login;
