const SESSION_DURATION = 2 * 60 * 60 * 1000;  
 

export const getCurrentTime = () => new Date().getTime();

export const checkSessionExpiry = () => {
  const loginTime = localStorage.getItem("loginTime");
  if (loginTime) {
    const currentTime = getCurrentTime();
    const timeElapsed = currentTime - parseInt(loginTime, 10);
    
    if (timeElapsed > SESSION_DURATION) {
      // Session expired
      alert("Your session has expired. Please log in again!");
      clearSession();
    }
  }
};

export const clearSession = () => {
  localStorage.removeItem("admin");
  localStorage.removeItem("token");
  localStorage.removeItem("tokenuser");
  localStorage.removeItem("favourite");
  localStorage.removeItem("useraddress");
  localStorage.removeItem("loginTime");
  location.reload(); // Reload the page to redirect to login or home
};

export const updateActivityTime = () => {
  localStorage.setItem("loginTime", getCurrentTime().toString());
};
