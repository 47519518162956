import React, { useState, useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import CryptoJS from 'crypto-js'; // Import crypto-js
import ExportApi from "../../api/ExportApi";

// Function to encrypt the API key
const encrypt = (text) => {
    if (!text || typeof text !== 'string' || text.length === 0) {
        console.error('Input text is undefined, not a string, or empty');
        return '';
    }

    const SECRET_KEY = '3JrY8l@#QwErTyUiOpAsDfGhJkLmN1';
    const hashedKey = CryptoJS.SHA256(SECRET_KEY).toString();
    const parsedKey = CryptoJS.enc.Hex.parse(hashedKey);

    const iv = CryptoJS.lib.WordArray.random(128 / 8);

    const encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(text), parsedKey, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });

    return iv.toString() + ':' + encrypted.toString();
};

// Function to decrypt the API key
const decrypt = (encryptedText) => {
    const parts = encryptedText.split(':');
    if (parts.length !== 2) {
        throw new Error('Invalid encrypted text format');
    }

    const iv = CryptoJS.enc.Hex.parse(parts[0]);
    const ciphertext = parts[1];

    const SECRET_KEY = '3JrY8l@#QwErTyUiOpAsDfGhJkLmN1';
    const hashedKey = CryptoJS.SHA256(SECRET_KEY).toString();
    const parsedKey = CryptoJS.enc.Hex.parse(hashedKey);

    const decrypted = CryptoJS.AES.decrypt(ciphertext, parsedKey, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });

    const decryptedText = decrypted.toString(CryptoJS.enc.Utf8);
    if (!decryptedText) {
        throw new Error('Decryption failed');
    }

    return decryptedText;
};

function ApiKeys() {
    const [apiKey, setApiKey] = useState('');
    const [isUpdating, setIsUpdating] = useState(false);

    const fetchApiKey = async () => {
        try {
            const res = await ExportApi.getapikey();
            if (res?.data?.apiKey) {
                const decryptedKey = decrypt(res.data.apiKey);
                setApiKey(decryptedKey);
                setIsUpdating(true);
            }
        } catch (error) {
            console.error('Error fetching API key:', error);
            toast.error('Error fetching API key');
        }
    };

    useEffect(() => {
        fetchApiKey();
    }, []);

    const handleInputChange = (e) => {
        setApiKey(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const encryptedApiKey = encrypt(apiKey);

        if (!encryptedApiKey) {
            toast.error('Failed to encrypt the API key');
            return;
        }

        try {
            let data;

            // Use ExportApi.updatekey for updating
            if (isUpdating) {
                data = await ExportApi.updatekey({ key: encryptedApiKey });
            } else {
                data = await ExportApi.saveapikey(encryptedApiKey);
            }

            if (data?.data?.message === "API key saved successfully." || data?.data?.message === "API key updated successfully.") {
                toast.success(isUpdating ? 'API Key updated successfully!' : 'API Key added successfully!');
                setIsUpdating(false);
                await fetchApiKey();
            } else {
                toast.error(data?.data?.message || 'Error occurred');
            }
        } catch (error) {
            console.error('Error saving API key:', error);
            toast.error('Error saving API key');
        }
    };

    return (
        <div>
            <h1>{isUpdating ? 'Update API Key' : 'Add API Key'}</h1>
            <Form onSubmit={handleSubmit}>
                <Form.Group controlId="apiKey">
                    <Form.Control
                        type="password"
                        value={apiKey}
                        onChange={handleInputChange}
                        placeholder="Enter your API Key"
                        required
                    />
                </Form.Group>
                <Button variant="primary" type="submit">
                    {isUpdating ? 'Update Key' : 'Add Key'}
                </Button>
            </Form>
        </div>
    );
}

export default ApiKeys;
