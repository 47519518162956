import React, { useEffect, useState, useContext } from 'react';
import '../products.css';
import axios from 'axios';
import ProductsItem from '../productItem/ProductsItem';
import { Link } from 'react-router-dom';
import UserContext from '../../../../context/UserContext';
import { toggleSelectedProduct } from '../../../../context/UserContext';
import ExportApi from "../../../../api/ExportApi";
import { BaseApi } from '../../../../api/BaseApi';

export default function ProductsList({ apiEndpoint, productType }) {
  const [productsData, setProductsData] = useState([]);
  const [productCheckedState, setProductCheckedState] = useState({});
  const [showLoader, setShowLoader] = useState(true);
  const [showMessage, setShowMessage] = useState(false);
  const { selectedProducts, setSelectedProducts } = useContext(UserContext);

  if (localStorage.getItem("tokenuser")) {
    var userId = JSON.parse(localStorage.getItem("tokenuser"))?.id 
  }else{
    var userId = JSON.parse(localStorage.getItem("admin"))?.id 
  }
 console.log(productType,"productType123");
  // console.log(apiEndpoint,"apiEndpoint>>>>>>>>>>123");
  // Products API
  useEffect(() => {
    BaseApi.post( apiEndpoint, {
      id: userId
    })
      .then(function (response) {
        // console.log(response,"response>>>>>>>apiEndpoint29");
        setProductsData(response?.data[productType]?.slice(0, 6));
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    // Set a timeout to show the message and hide the loader
    const timer = setTimeout(() => {
      setShowLoader(false);
      setShowMessage(true);
    }, 2000); // Adjust the timeout duration as needed (2000ms = 2 seconds)

    // Clear timeout if the component unmounts
    return () => clearTimeout(timer);
  }, []);

  // Add Products to Compare
  const handleCheckboxChange = (productId) => {
    toggleSelectedProduct(productId, selectedProducts, setSelectedProducts);
  };

  // Remove a Single Product from Compare Section
  const handleRemoveProduct = (productId) => {
    setSelectedProducts((prevSelectedProducts) =>
      prevSelectedProducts.filter((product) => product._id !== productId)
    );

    setProductCheckedState((prevState) => {
      const updatedState = { ...prevState };
      delete updatedState[productId];
      return updatedState;
    });
  };

  // Remove all Products from Compare Section
  const handleClearAll = () => {
    setSelectedProducts([]);
      localStorage.removeItem("Compare");
  }
console.log(productsData,"")
  return (
    <div className='deals'>
      <div className="row products-list">
        {/* {productsData?.length > 0 ? (
          productsData.map((data) => (
            <ProductsItem
              key={data._id}
              chipset={data.chipset}
              id={data._id}
              thumbnail={data.image}
              title={data.title}
              series={data.series}
              brand={data.brand}
              price={data.type == 1 ? data.new_retail_website_price : (data?.lowest_ask > 0 ? data?.lowest_ask : "TBD")  }
              model={data.model}
              data={data}
              condition={data.type}
              isChecked={productCheckedState[data._id]}
              onCheckboxChange={handleCheckboxChange}
            />
          ))
        ):
        <h3 className='no-products'>No products right now</h3>} */}
        {/* {productsData == null? (
 
  <div className='custom-loader'>
    <svg
      version="1.1"
      id="L9"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      enableBackground="new 0 0 0 0"
      xmlSpace="preserve"
    >
      <path
        fill="blue"
        d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
      >
        <animateTransform
          attributeName="transform"
          attributeType="XML"
          type="rotate"
          dur="1s"
          from="0 50 50"
          to="360 50 50"
          repeatCount="indefinite"
        />
      </path>
    </svg>
  </div>
) : productsData?.length >0 ? (
 
  productsData.map((data) => (
    <ProductsItem
      key={data._id}
      chipset={data.chipset}
      id={data._id}
      thumbnail={data.image}
      title={data.title}
      series={data.series}
      brand={data.brand}
      price={data.type == 1 ? data.new_retail_website_price : (data?.lowest_ask > 0 ? data?.lowest_ask : "TBD")}
      model={data.model}
      data={data}
      condition={data.type}
      isChecked={productCheckedState[data._id]}
      onCheckboxChange={handleCheckboxChange}
    />
  ))
) : (
 
  <h3 className='no-products'>No products right now</h3>
)} */}
{productsData == null ? (
  <div className='custom-loader'>
    <svg
      version="1.1"
      id="L9"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      enableBackground="new 0 0 0 0"
      xmlSpace="preserve"
    >
      <path
        fill="blue"
        d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
      >
        <animateTransform
          attributeName="transform"
          attributeType="XML"
          type="rotate"
          dur="1s"
          from="0 50 50"
          to="360 50 50"
          repeatCount="indefinite"
        />
      </path>
    </svg>
  </div>
) : productsData.length === 0 ? (
 
  // Render message when productsData is not null but has no items
    //<h3 className='no-products'>No products right now</h3>
  // <div className='custom-loader'>

  //   <svg
  //     version="1.1"
  //     id="L9"
  //     xmlns="http://www.w3.org/2000/svg"
  //     xmlnsXlink="http://www.w3.org/1999/xlink"
  //     x="0px"
  //     y="0px"
  //     viewBox="0 0 100 100"
  //     enableBackground="new 0 0 0 0"
  //     xmlSpace="preserve"
  //   >
  //     <path
  //       fill="blue"
  //       d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
  //     >
  //       <animateTransform
  //         attributeName="transform"
  //         attributeType="XML"
  //         type="rotate"
  //         dur="1s"
  //         from="0 50 50"
  //         to="360 50 50"
  //         repeatCount="indefinite"
  //       />
  //     </path>
  //   </svg>
  // </div>

<> 
<div>
  {showLoader && (
    <div className='custom-loader'>
      <svg
        version="1.1"
        id="L9"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 100 100"
        enableBackground="new 0 0 0 0"
        xmlSpace="preserve"
      >
        <path
          fill="blue"
          d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
        >
          <animateTransform
            attributeName="transform"
            attributeType="XML"
            type="rotate"
            dur="1s"
            from="0 50 50"
            to="360 50 50"
            repeatCount="indefinite"
          />
        </path>
      </svg>
    </div>
  )}

  {showMessage && (
    <h3 className='no-products'>No products right now</h3>
  )}
</div>
</>
) : (
  // Render products when productsData has items
  productsData.map((data) => (
    <ProductsItem
      key={data?._id}
      chipset={data?.chipset}
      id={data?._id}
      thumbnail={data?.image}
      title={data?.title}
      series={data?.series}
      brand={data?.brand}
      price={data.type == 1 ? data.new_retail_website_price : (data?.lowest_ask > 0 ? data?.lowest_ask : "TBD")}
      model={data?.model}
      data={data}
      condition={data?.type}
      isChecked={productCheckedState[data?._id]}
      onCheckboxChange={handleCheckboxChange}
    />
  ))
)}

        {selectedProducts.length > 0 && (
          <div className="selected-products d-flex justify-content-between align-items-center px-lg-5">
            <div className="product-list d-flex w-100">
              {selectedProducts.map((product) => (
                <div key={product?._id} className='bg-light selected-box p-3'>
                  <span className='d-flex position-absolute fw-bold' style={{ color: product.chipset === 'AMD' ? 'rgb(186, 0, 21)' : product.chipset === 'Nvidia' ? 'rgb(114, 179, 0)' : 'inherit' }}>{product.chipset}</span>
                  <div className='d-flex mt-2 w-100 gap-2 selected-content'>
                  <img src={product?.image[0]} alt="thumbnail" style={{maxWidth: 80, objectFit: 'cover'}} />
                    <div className="product-details d-flex justify-content-between w-100 pt-1 ms-2 ms-md-0">
                      <div className="desc w-100">
                        <p className='text-start mb-0'>{product.title}</p>
                        <p className='text-start mb-0 discount fw-bold'>{product?.brand}</p>
                        <p className='text-start mb-0 discount fw-bold'>{product?.series}</p>
                        <p className='text-start mb-0 discount fw-bold'>{product?.model}</p>
                        <p className='text-start fw-bold mt-2'>{product?.condition}</p>
                        <h5 className='text-start fw-bold mt-2'>{product?.price}</h5>
                      </div>
                    </div>
                  </div>
                  <button className='close' onClick={() => handleRemoveProduct(product?._id)}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                      <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"></path>
                    </svg>
                  </button>
                </div>
              ))}
            </div>
            <div className="buttons ps-3">
              <Link to="/compareBuySell">
                <button className='px-4 btn btn-primary'>Compare</button>
              </Link>
              <button className='px-4 bg-transparent text-blue border-0 btn btn-primary' onClick={handleClearAll}>Clear All</button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
