import React, { useEffect, useState, useCallback, useRef } from "react";
import {
  Form,
  Button
} from "react-bootstrap";
import { IconButton } from "@mui/material";
import ExportApi from "../../../api/ExportApi";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useForm ,Controller } from 'react-hook-form';
import { CKEditor } from "@ckeditor/ckeditor5-react";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { ClassicEditor,Font, Bold,Italic ,Link ,List ,
    BlockQuote ,Heading, Image ,MediaEmbed 
    ,Table as Tabelck ,TableToolbar ,Undo
  } from 'ckeditor5';
import "../HelpManagement.css"

export default function CreateCategory() {

    const {register, handleSubmit, setValue, control, reset, formState: { errors },} = useForm();

    const [count, setCount] = useState();
    const [categoryData, setCategoryData] = useState();
    
      const onSubmit = (data) => {
        setCount(0)
        // console.log(data);
        // return
        // const formData = new FormData();
        // formData.append('name', data?.name);
        // formData.append('description', data?.description);        
        ExportApi.addHelpCategory(data?.name , data?.description).then((resp)=>{
            console.log(resp?.data);
            const data = resp?.data;
            if(data?.status == 201){
                toast.success(data?.message)
                reset();
                setCount(1)
                location.reload();
            }else if (data?.status == 203) {
                toast.error(data?.message)
                setCount(1)
            }else{
                toast.error(data?.message)
                setCount(1)
            }
        }).catch((err)=> console.log(err))
        setCount(1)
      };
    
      useEffect(() => {
        getCategoryData()
      }, [])

      const getCategoryData = () =>{
        ExportApi.getHelpData('').then((resp)=>{
            console.log(resp?.data);
            const data = resp?.data;
            if(data?.status == 201){
                console.log(data?.detail);
                setCategoryData(data?.detail);
                setCount(1)
            }else{
                toast.error(data?.message)
                setCount(1)
            }
        }).catch((err)=> console.log(err))
        setCount(1)
      }
      
      return (
<>
        {count == 0 ?  
            <div className="loader-icon" style={{ marginBlock: "80px" }}>
              <i className="fa-solid fa-spinner fa-spin-pulse"></i>
            </div>
        :
        <Form onSubmit={handleSubmit(onSubmit)} className="py-5">
            <h2 className="mb-4">Create Category</h2>
            <Form.Group controlId="name" className="mb-4">
                <Form.Label>Category Name</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Enter first name"
                    {...register('name', { required: 'Category Name is required' })}
                />
                {errors.name && <p className="form-error">{errors.name.message}</p>}
            </Form.Group>

            <Form.Label>Category Description</Form.Label>
                <Controller
                    name="description"
                    control={control}
                    defaultValue="" // Set the initial value here if needed
                    rules={{ required: 'Category Description is required' }} // Add rules if needed
                    render={({ field }) => (
                    <CKEditor
                        editor={ClassicEditor}
                        config={{
                            plugins: [
                              Font, Bold, Italic, Link, List, BlockQuote, Heading, Image, 
                               MediaEmbed, Tabelck, TableToolbar, Undo
                            ],
                            toolbar: {
                              items: [
                                'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', '|',
                                'indent', 'outdent', '|', 'blockQuote', 'insertTable', '|', 'undo', 'redo',
                                'mediaEmbed', 'imageUpload','fontFamily',
                              ],
                            },
                            height: "400px",
                            mediaEmbed: {
                              previewsInData: true
                            },
                            image: {
                              toolbar: [
                                'imageTextAlternative',
                                '|',
                                'imageStyle:full',
                                'imageStyle:side'
                              ]
                            },
                            image: {
                              styles: [
                                'alignLeft',
                                'alignCenter',
                                'alignRight'
                              ]
                            },
                            fontFamily: {
                              options: [
                               
                                'Helvetica',
                                'Open Sans'
                              ]
                          },
                          }}
                        onChange={(event, editor) => {
                        const data = editor.getData();
                        setValue('description', data); // Update the form value
                        field.onChange(data); // Update the field value
                        }}
                    />
                    )}
                />
                {errors.description && <p className="form-error">{errors.description.message}</p>}

                {/* <Form.Group controlId="name">
                    <Form.Label>Select Category</Form.Label>
                        <Controller
                            name="categoryId" // Specify the name for the select box
                            control={control}
                            defaultValue="" // Set the initial value if needed
                            rules={{ required: 'Select an categoryId' }} // Add rules if needed
                            render={({ field }) => (
                            <select {...field}>
                                <option value="" disabled>
                                Select an option
                                </option>
                                {categoryData?.length > 0 ? categoryData.map((item)=>(
                                <>
                                    <option value={item?._id}>{item?.name}</option>
                                </>
                                ))
                                : ""}
                            </select>
                        )}
                        />
                    {errors.categoryId && <p className="form-error">{errors.categoryId.message}</p>}  
                </Form.Group>       */}
            <Button variant="primary" type="submit" className="mt-5">
                Submit
            </Button>
        </Form>
        }
      </>

      );
    };