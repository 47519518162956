import React, { useEffect, useState, useCallback } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Row,
  Table,
  Modal,
} from "react-bootstrap";
import UserBuybaner from "./UserBuybaner";
import Header from "./header";
import ExportApi from "../api/ExportApi";
import { useNavigate } from "react-router-dom";
import "react-lazy-load-image-component/src/effects/blur.css";
import OutsideClickHandler from "react-outside-click-handler";
import Login from "./Login";
// import UserAskBidList from "./UserAskBidList";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { toast } from "react-toastify";
import ReactPaginate from "react-paginate";
import _ from "lodash";

const UserBuySell = () => {
  let imageUrl = process.env.REACT_APP_IMAGEBASEURL;
  const htmlString =
    "<p class='tl_name'><strong>Why batch</strong><p class='inn-text'>If you only want to buy one item but would like to<br/> bid on multiple items to get the best deal this tool<br/> is for you!<p/><br/><strong>How it works</strong><p class='inn-text'>When any item in the batch sells to you, all of <br/> your existing bids on the other items in the batch<br/> will be automatically deactivated. This makes it<br/> safe for you to bid on many items without risking<br/> to purchase more than one item.</p><br/><strong>Instructions</strong><br/><p class='inn-text'><strong>Create Batch</strong><br/>1. Select the items you would like to Batch.<br/>2. Click batch (All selected items will be Batched).<br/><br/><strong>Remove items</strong><br/>1. Select items you would like to remove.<br/>2. Click batch (All selected items will be removed from Batch).<br/><br/><strong>Add Batch</strong><br/>1. Select items you would like to add.<br/>2. Click batch (All selected items will be added to Batch).</p>";
  const [productData, setproductData] = useState();
  const [userId, setUserId] = useState();
  const [userType, setUserType] = useState();
  const [productData1, setproductData1] = useState();
  const [descriptionindex, setdescriptionindex] = useState();
  const [ask, setAsk] = useState();
  const [showModal, setShowModal] = useState(false);
  const [Bid, setBid] = useState();
  const [AskBid, setAskBid] = useState();
  const [currentSortOrder, setCurrentSortOrder] = useState(1);
  const [currentSortKey, setCurrentSortKey] = useState(null);
  const [hide, setHide] = useState();
  const [FilterList, setFilterList] = useState();
  const [condition1, setcondition1] = useState([]);
  const [brandShow, setBrandShow] = useState(false);
  const [chipsetShow, setChipsetShow] = useState(false);
  const [seriesShow, setSeriesShow] = useState(false);
  const [chipset, setchipset] = useState([]);
  const [brand, setbrand] = useState([]);
  const [modelShow, setModelShow] = useState(false);
  const [bidStatus, setBidStatus] = useState(false);
  const [askStatus, setAskStatus] = useState(false);
  const [loading, setLoading] = useState(0);
  const [showFilterModel, setShowFilterModel] = useState(false);
  const [conditionKey, setconditionKey] = useState([]);
  const [chipsetKey, setchipsetKey] = useState([]);
  const [brandKey, setbrandKey] = useState([]);
  const [seriesKey, setseriesKey] = useState([]);
  const [modalKey, setModalKey] = useState([]);
  const [condition, setcondition] = useState([]);
  const [series, setseries] = useState([]);
  const [model, setmodel] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [selectCheckBox, setSelectCheckBox] = useState(false);
  const [hideBatchStatus, setHideBatchStatus] = useState(false);
  const [batchCount, setBatchCount] = useState();
  const [batchData, setBatchData] = useState([]);
  const [batchStatus, setBatchStatus] = useState();
  const [askLoading, setAskLoading] = useState(0);
  const [showButtonLoader, setShowButtonLoader] = useState(false);

  const [alreadyCreatedBatch, setAlreadyCreatedBatch] = useState(false);
  const [descriptionindex1, setdescriptionindex1] = useState();
  const [pageNumber, setPageNumber] = useState();
  const [totalPages, setTotalPages] = useState(0);
  const [mainloader, setMainloader] = useState(false);
  const [modelShowMore, setModelShowMore] = useState(false);
  const [total, setTotal] = useState();
  const [pageOffset, setPageOffset] = useState(0);
  const [askliston, setAskliston] = useState(false);
  const [loader, setLoader] = useState(false);
  // const [allproducts, setAllProducts] = useState();
  const [uncheckedbox, setUncheckedBox] = useState(true);
  const [searchString, setSearchString] = useState("");
  const [showbatch, setShowBatch] = useState(false);
  const [showaskbatch, setShowAskBatch] = useState(false);
  const [sortColumn, setSortColumn] = useState(''); // Column to sort by
  const [sortOrder, setSortOrder] = useState(''); 

  console.log(totalPages?.length, "totalPages123");
  const itemsPerPage = 10;

  const navigate = useNavigate();

  console.log(askliston, "askliston11");

  const [showMore, setShowMore] = useState({
    showMore: false,
    showLess: false,
  });
  console.log(askStatus, "askStatus11");
  useEffect(() => {
    const chip =
      chipset?.length +
      condition1?.length +
      brand?.length +
      series?.length +
      model?.length;
    setTotal(chip);

    if (chip > 4 && !showMore.showLess) {
      setShowMore((prev) => ({
        ...prev,
        showMore: true,
      }));
    } else if (chip > 4 && showMore.showLess) {
      setShowMore((prev) => ({
        ...prev,
        showMore: false,
      }));
    } else {
      setShowMore((prev) => ({
        ...prev,
        showMore: false,
        showLess: false,
      }));
    }
    console.log(chip, "chip123");
  }, [chipset, condition1, brand, series, model]);

  //for the Search
  // const hideData = (data) => {
  //   setHide(data);
  // };

  // useEffect(() => {
  //   localStorage.removeItem("pageNumber");
  // }, []);
  //for the User Buy Sell page Search that takes an event object (e) as a parameter

  console.log(selectedData, "selectedData334");
  // useEffect(() => {
  //   const fetchProducts = async () => {
  //     try {
  //       const resp = await ExportApi.Alllatestproducts();
  //       console.log(resp.data.data, "allproducts"); // For debugging purposes
  //       if (resp?.data?.data) {
  //         setAllProducts(resp?.data?.data);
  //       } else {
  //         console.error("Unexpected response format", resp);
  //       }
  //     } catch (error) {
  //       console.error("Error fetching products:", error);
  //     }
  //   };

  //   fetchProducts();
  // }, []);


  // const handleSearch = (e) => {
  //   if (e == "") {
  //     console.log("datanew1");
  //     setproductData([...productData1]);
  //   } else {
  //     let FilterproductData = allproducts?.filter(
  //       (val) =>
  //         val.brand.toLowerCase().includes(e.toLowerCase()) ||
  //         val.chipset.toLowerCase().includes(e.toLowerCase()) ||
  //         val.model.toLowerCase().includes(e.toLowerCase()) ||
  //         val.series.toLowerCase().includes(e.toLowerCase())
  //     );
  //     console.log("datanew2");
  //     setproductData([...FilterproductData]);
  //   }
  // };
  // const debounceSearch = useCallback(
  //   _.debounce((search) => {
  //     setSearchString(search);
  //     setPageOffset(0);

  //     const data = {
  //       condition: [],
  //       chipset: [],
  //       brand: [],
  //       series: [],
  //       model: [],
  //       currentPage: 1,
  //       searchString: search,
  //     };

  //     ExportApi.buysellfilter(data)
  //       .then((resp) => {

  //         console.log(resp, "resppp12");

  //         const totalpages = resp?.data?.Total;
  //         if (resp?.data?.Data.length > 0) {
  //           let products = resp?.data?.Data;
  //           console.log(products, "products11");

  //           setTotalPages(totalpages);

  //           let BatchStatus = products.filter((item) => item?.batchStatus === true);
  //           setBatchData(BatchStatus);
  //           setproductData(products);
  //           let BCount = BatchStatus.length / 2;
  //           setBatchCount(BCount === 0.5 ? BCount : Math.ceil(BCount) - 1);

  //         } else {
  //           setproductData([]);
  //         }
  //       })
  //       .catch((err) => {
  //         console.log(err);

  //       });
  //   }, 300),
  //   []
  // );

  const debounceSearch = useCallback(
    _.debounce((search) => {
      setMainloader(true);
      const data = {
        condition: [],
        chipset: [],
        brand: [],
        series: [],
        model: [],
        currentPage: 1,
        searchString: search,
        sortColumn,      
        sortOrder: sortOrder,
      };

      ExportApi.buysellfilter(data)
        .then((resp) => {
          setMainloader(false);
          console.log(resp, "resppp12");
          let products = resp?.data?.Data;
          const totalpages = resp?.data?.Total;
          setTotalPages(totalpages);

          let BatchStatus = products.filter(
            (item) => item?.batchStatus === true
          );
          console.log(BatchStatus, "BatchStatus441");
          if (BatchStatus?.length > 1) {
            let BCount = BatchStatus.length / 2;
            setBatchCount(BCount === 0.5 ? BCount : Math.ceil(BCount) - 1);
            setBatchData(BatchStatus);
          } else {
            setBatchData([]);
          }
          setproductData(products);
        })
        .catch((err) => {
          setMainloader(false);
          console.log(err);
        });
    }, 1000),
    []
  );

  const handleSearch = (e) => {
    const search = e.trim();

    debounceSearch(search);
  };

  // // Handle search input
  // const handleSearch = (e) => {
  //   const search = e.trim();
  //   debounceSearch(search);
  // };

  //for the Table Sort that takes a sorting key (key) as a parameter
  const sortHereLowestAsk = (key, data) => {
    const sortOrder = key === currentSortKey ? -currentSortOrder : 1;
    // console.log(sortOrder);
    // return
    if (data == "ask") {
      // Filter out items with lowest_ask equal to 0 and type not equal to 1
      const zeroLowestAskData = productData.filter(
        (item) => item.lowest_ask === 0 && item.type !== 1
      );
      const filteredData = productData.filter(
        (item) => item.lowest_ask !== 0 || item.type === 1
      );
      console.log(filteredData);

      console.log(zeroLowestAskData.length);
      const sortedData = [...filteredData].sort((a, b) => {
        let aValue, bValue;

        if (a["type"] == 1) {
          // Sort by new_retail_website_price
          aValue = a.new_retail_website_price;
        } else {
          // Sort by lowest_ask
          aValue = a.lowest_ask;
        }

        if (b["type"] == 1) {
          // Sort by new_retail_website_price
          bValue = b.new_retail_website_price;
        } else {
          // Sort by lowest_ask
          bValue = b.lowest_ask;
        }

        if (aValue < bValue) return -1 * sortOrder;
        if (aValue > bValue) return sortOrder;
        return 0;
      });

      const finalSortedData = [...sortedData, ...zeroLowestAskData];
      console.log(finalSortedData, "finalSortedData");
      setCurrentSortKey(key);
      setCurrentSortOrder(sortOrder);
      console.log("datanew3");
      setproductData(finalSortedData);
    } else {
      const zeroHighestBidData = productData.filter(
        (item) => item.highest_bid === 0 || item.type === 1
      );
      const filteredData = productData.filter(
        (item) => item.highest_bid !== 0 && item.type !== 1
      );
      //console.log(filteredData.length);

      //console.log(zeroHighestBidData.length);
      const sortedData = [...filteredData].sort((a, b) => {
        let aValue, bValue;

        if (a["type"] == 1) {
          // Sort by new_retail_website_price
          aValue = a.new_retail_website_price;
        } else {
          // Sort by lowest_ask
          aValue = a.highest_bid;
        }

        if (b["type"] == 1) {
          bValue = b.new_retail_website_price;
        } else {
          bValue = b.highest_bid;
        }
        if (aValue < bValue) return -1 * sortOrder;
        if (aValue > bValue) return sortOrder;
        return 0;
      });

      const finalSortedData = [...sortedData, ...zeroHighestBidData];
      console.log(finalSortedData, "finalSortedData1");
      setCurrentSortKey(key);
      setCurrentSortOrder(sortOrder);
      console.log("datanew4");
      setproductData(finalSortedData);
    }
  };

  //key used for the get the name of the variable for sorting
  // Sorting functions for Order Id.
  //key used for the order id filter
  const sortHere = (key) => {
    const sortOrder = key === currentSortKey ? -currentSortOrder : 1;
    const sortedProducts = productData.sort((a, b) => {
      if (a[key] < b[key]) return -1 * sortOrder;
      if (a[key] > b[key]) return sortOrder;
      return 0;
    });
    setCurrentSortKey(key);
    setCurrentSortOrder(sortOrder);
  };
  //for getting the ask of product
  // here askStatus is geted from the useState to make it work like switch,to make working it oppositelty.custom-tooltip_buy-sell
  // const handleViewAsk = () => {
  //   setLoading(1);
  //   setSelectCheckBox(false);
  //   if (userType == "user") {
  //     // let status = !askStatus;
  //     // setAskStatus(status);
  //     setBidStatus(false);
  //     if (askStatus == true) {
  //       HandleGetAskList(userId);
  //        setAskliston(true);
  //     } else if(askStatus == false) {
  //        setAskliston(true);
  //       HandleGetProductData(userId);
  //     }
  //   } else if (userType == "admin") {
  //     // let status = !askStatus;
  //     // setAskStatus(status);
  //     setBidStatus(false);
  //     if (askStatus == true) {
  //       HandleGetAskList(userId);
  //     } else if (askStatus == false) {
  //       HandleGetProductData(userId);
  //     }
  //   } else {
  //     setShowModal(true);
  //   }
  // };

  const handleViewAsk = () => {
    setShowAskBatch(!showaskbatch);
    setLoading(1);
    setSelectCheckBox(false);

    if (userType === "user" || userType === "admin") {
      const newAskStatus = !askStatus;
      setAskStatus(newAskStatus);
      setBidStatus(false);

      if (newAskStatus) {
        HandleGetAskList(userId);
        setAskliston(true);
        console.log("if>>>>>>>>>>>>>>part>>>");
      } else {
        HandleGetProductData1(userId);
        console.log("elseeeeeeee>>>>>>>>>>>>>>part>>>");
        setAskliston(false);
      }
    } else {
      setShowModal(true);
    }
  };

  //for getting the bid of product
  // here bidStatus is geted from the useState to make it work like switch,to make working it oppositelty.
  const handleViewBid = () => {
    setShowBatch(!showbatch);
    setLoading(1);
    setSelectCheckBox(false);
    if (userType == "user") {
      let status = !bidStatus;
      setBidStatus(status);
      setAskStatus(false);
      if (status) {
        HandleGetBidList(userId);
        setAskliston(true);
      } else {
        HandleGetProductData1(userId);
        setAskliston(false);
        // filtersearch();
      }
    } else if (userType == "admin") {
      let status = !bidStatus;
      setBidStatus(status);
      setAskStatus(false);
      if (status) {
        HandleGetBidList(userId);
      } else {
        HandleGetProductData1(userId);
        // filtersearch();
      }
    } else {
      setShowModal(true);
    }
  };

  const HandleGetProductData = () => {
    setMainloader(true);
    const data = {
      condition: [],
      chipset: [],
      brand: [],
      series: [],
      model: [],
      currentPage: pageNumber + 1,
      searchString: searchString,
      sortColumn,      
      sortOrder: sortOrder,
    };

    ExportApi.buysellfilter(data)
      .then((resp) => {
        console.log(resp?.data?.Data, "HandleGetProductData1");
        setMainloader(false);
        const totalpages = resp?.data?.Total;
        //console.log(totalpages, "totalpages22");
        if (resp?.data?.Data?.length > 0) {
          let data = resp.data.Data;

          setTotalPages(totalpages);
          console.log(data, "data>>>>>!23");
          let BatchStatus = data?.filter((item) => item?.batchStatus == true);
          console.log(BatchStatus, "BatchStatus477");
          if (BatchStatus?.length > 1) {
            setBatchData(BatchStatus);
            let BCount = BatchStatus?.length / 2;
            if (BCount == 0.5) {
              setBatchCount(BCount);
            } else {
              setBatchCount(Math.ceil(BCount) - 1);
            }
          } else {
            setBatchData([]);
          }

          // setSelectedData(BatchStatus);

          setproductData(data);
          setproductData1(data);

          setLoading(1);
          // setShowLoader(false);
        } else {
          console.log("setLoading(2)276");
          setproductData(data);
          setLoading(2);
        }
      })
      .catch((err) => {
        console.log(err);
        setMainloader(false);
      });
  };

  // Create a new array called sortedData using the sort() method on the Data array
  // her a and b are the two parameters,here a takes the new value and B checks the comparing for the sorting.
  // const sortedData = productData?.sort((a, b) => {
  //   if (a.batchStatus && !b.batchStatus) {
  //     return -1;
  //   }
  //   if (!a.batchStatus && b.batchStatus) {
  //     return 1;
  //   }
  //   return 0;
  // });

  // user login data
  // here response is the data that is geted from the Api
  const HandleGetProductData1 = () => {
    //ExportApi.GetAllProduct()
    // ExportApi.getallproducts()
    setMainloader(true);
    // const pageno = localStorage.getItem("pageNumber") || 0;
    // console.log(pageno, "pageno11");

    //ExportApi.testallproducts(pageno)
    const data = {
      condition: [],
      chipset: [],
      brand: [],
      series: [],
      model: [],
      currentPage: 0,
      searchString: searchString,
      sortColumn,      
      sortOrder: sortOrder,
    };

    ExportApi.buysellfilter(data)
      .then((resp) => {
        const data = resp?.data?.Data;
        const totalpages = resp?.data?.Total;

        setMainloader(false);
        if (data?.length > 0) {
          setTotalPages(totalpages);
          console.log("datanew6");
          setproductData(data);
          setproductData1(data);
          setLoading(1);
        } else {
          console.log("setLoading(2)331");
          setproductData(data);
          setLoading(2);
        }
      })
      .catch((err) => {
        console.log(err);
        setMainloader(false);
      });
  };

  useEffect(() => {
    //HandleGetProductData();
    // HandleGetProductData1();
    filtersearch();
  }, [pageNumber]);

  const HandleGetAskList = (id) => {
    // setShowLoader(true);
    let Data = [];
    ExportApi.askList(id)
      .then((resp) => {
        console.log(resp?.data?.data, "resp321");
        for (let index = 0; index < resp.data.data?.length; index++) {
          let obj = {};
          const element = resp.data.data[index];
          obj = { ...element, ...element.productId };
          Data.push(obj);
        }

        const batchStatusProducts = resp?.data?.data.filter(
          (product) => product.productId.batchStatus === true
        );

        console.log(batchStatusProducts, "BatchStatusask");

        if (batchStatusProducts?.length > 1) {
          setBatchData(batchStatusProducts);
        } else {
          setBatchData([]);
        }
        setLoading(1);
        setTotalPages(Data);
        console.log(Data, "Data381");
        // setShowLoader(false);
        setproductData(Data);
        setproductData1(Data);
      })
      .catch((err) => console.log(err));
    setLoading(1);
  };

  const HandleGetBidList = (id) => {
    let Data = [];
    ExportApi.getBidList(id)
      .then((resp) => {
        for (let index = 0; index < resp.data.data?.length; index++) {
          let obj = {};
          const element = resp.data.data[index];
          obj = { ...element, ...element.productId };
          Data.push(obj);
        }

        let BatchStatus = resp?.data?.data?.filter(
          (item) => item?.batchStatus == true
        );
        console.log(BatchStatus, "BatchStatus477");
        if (BatchStatus?.length > 1) {
          setBatchData(BatchStatus);
        } else {
          setBatchData([]);
        }

        setTotalPages(Data);
        setproductData(Data);
        setproductData1(Data);

        setLoading(1);
        // setShowLoader(false);
      })
      .catch((err) => console.log(err));
    setLoading(1);
  };

  const Handleask = (id) => {
    setAskLoading(0);
    ExportApi.getask(id)
      .then((resp) => {
        setAsk(resp.data.data);
        setAskLoading(1);
      })
      .catch((err) => console.log(err));
  };

  const Handlebid = (id) => {
    setAskLoading(0);
    ExportApi.getBid(id)
      .then((resp) => {
        setBid(resp.data.data);
        setAskLoading(1);
      })
      .catch((err) => console.log(err));
  };

  const ASKBID = (id, i) => {
    Handleask(id);
    Handlebid(id);
    setAskBid(i);
  };

  // const handleCheck = (data, id) => {
  //   if (!userId) {
  //     toast.error("Please login first");
  //   }

  //   setBatchStatus(data);
  //   // const result = productData.filter((item) => item.batchStatus == true);
  //   // if (result?.length > 0) {
  //   //   handleUpdateBatch(data, id);
  //   // } else {
  //     let productId = id;

  //     // setSelectedData(selectedData);
  //     const result1 = productData.find((Element) => Element._id == productId);
  //     if (result1.batchStatus) {
  //       console.log('hjhjhhjhhj')
  //       result1["batchStatus"] = false;
  //       const index = selectedData.findIndex((x) => x._id == result1._id)
  //       selectedData.splice(index,1)
  //       setSelectedData(selectedData)

  //     }else{
  //       result1["batchStatus"] = true;
  //       console.log('result1',result1)
  //       const index = productData.findIndex((x) => x._id == result1._id);
  //       productData[index] = result1;
  //       console.log('productData',productData)
  //       selectedData.push(result1._id);
  //       setSelectedData([...selectedData]);
  //       // let productId1 = result1._id;

  //     }
  //     // productData.map((item) => {
  //     //   item["batchStatus"] = true;
  //     //   return { ...item };
  //     // });
  //   // }
  // };

  //id used for the specific product
  // const handleCheck = (data, id) => {
  //   setUncheckedBox(false);
  //   if (!userId) {
  //     toast.error("Please login first");
  //     return;
  //   }
  //   const product_id = id;
  //   const updatedList = productData?.map((item) => {
  //     if (item._id === product_id) {
  //       item.isSelected = data;
  //     }
  //     return item;
  //   });

  //   const allSelected = updatedList?.every((item) => item.isSelected);
  //   // console.logs([...updatedList], "[...updatedList]");
  //   // console.log("datanew8");
  //   setproductData([...updatedList]);
  //   if (allSelected) {
  //     setSelectCheckBox(true);
  //     setSelectedData(updatedList.map((item) => item._id));
  //   } else {
  //     setSelectCheckBox(false);
  //     setSelectedData(
  //       updatedList.filter((item) => item.isSelected).map((item) => item._id)
  //     );
  //   }
  // };

  const handleCheck = (data, id) => {
    console.log(selectedData, "handlecheck1");
    setUncheckedBox(false);

    if (!userId) {
      toast.error("Please login first");
      return;
    }

    const updatedList = productData?.map((item) => {
      if (item._id === id) {
        return { ...item, isSelected: data };
      }
      return item;
    });

    // Set the updated product list
    setproductData([...updatedList]);

    if (selectedData.includes(id)) {
      const updatedselecteddata = selectedData.filter(
        (item) => !item.includes(id)
      );
      setSelectedData([...updatedselecteddata]);
    } else {
      setSelectedData([...selectedData, id]);
    }
  };
  useEffect(() => {
    if (selectedData?.length == productData?.length) {
      setSelectCheckBox(true);
    } else {
      setSelectCheckBox(false);
    }
  }, [selectedData]);

  console.log(selectedData.length, "lenght11");

  const handleUpdateBatch = (data, id) => {
    setLoading(0);
    if (userId) {
      ExportApi.updateBatch(data, id, userId).then((resp) => {
        if (resp?.data?.message == "Batch updated successfully") {
          setBidStatus(false);
          setShowBatch(false);
          setAskStatus(false);
          setShowAskBatch(false);
          toast.success("Batch update Successfully");
          HandleGetProductData(userId);
          // HandleGetFilterListData();
          setSelectCheckBox(false);
        } else {
          toast.error("Something went wrong!!!! , try again");
        }
      });
    }
  };

  const handleSelectAll = (e) => {
    if (!userId) {
      toast.error("Please login first");
      return;
    }

    const result = e.target.checked;
    setSelectCheckBox(result);

    const updatedProductData = productData.map((item) => {
      return { ...item, isSelected: result };
    });

    setproductData(updatedProductData);

    if (result) {
      const selectedIds = updatedProductData.map((item) => item._id);
      setSelectedData(selectedIds);
    } else {
      setSelectedData([]);
    }
  };

  // const handleSelectAll = () => {

  //   if (selectedData.length === productData.length) {
  //     setSelectedData([]);
  //     productData.map((item) => {
  //       handleUpdateBatch(false, item._id);
  //     });
  //     // for (let i = 0; i < productData.length; i++) {
  //     //   productData[i].batchStatus = false;
  //     //   handleUpdateBatch(false, productData[i]._id);
  //     // }
  //     // setproductData([...productData]);
  //   } else {
  //     const filterData = productData?.map((item) => item._id);
  //     setSelectedData(filterData);
  //     productData.map((item) => {
  //       handleUpdateBatch(true, item._id);
  //     });
  //     // for (let i = 0; i < productData.length; i++) {
  //     //   console.log(productData[i]._id);
  //     //   productData[i].batchStatus = true;
  //     //   handleUpdateBatch(true, productData[i]._id);
  //     // }
  //     // setproductData([...productData]);
  //   }
  // };

  //to show the filter Modal
  const handlefilterModal = () => {
    setShowFilterModel(true);
  };

  //get the data of the filter
  const HandleGetFilterListData = () => {
    ExportApi.GetFilterData()
      .then((resp) => {
        console.log(resp, "GetFilterData");
        let chipsetKeyArray = [];
        let conditionKeyArray = [];
        let brandKeyArray = [];
        let modalKeyArray = [];
        let seriesKeyArray = [];
        resp?.data.data?.chipset?.map((val, i) => {
          let key = { [val]: false };
          chipsetKeyArray.push(key);
        });
        setchipsetKey([...chipsetKeyArray]);
        resp?.data.data?.condition?.map((val) => {
          if (val == 1) {
            let key = { ["New-Retail"]: false };
            conditionKeyArray.push(key);
          } else if (val == 2) {
            let key = { ["New-2nd Hand"]: false };
            conditionKeyArray.push(key);
          } else {
            let key = { ["Used"]: false };
            conditionKeyArray.push(key);
          }
        });
        setconditionKey([...conditionKeyArray]);
        resp?.data.data?.series?.map((val) => {
          let key = { [val]: false };
          seriesKeyArray.push(key);
        });
        setseriesKey([...seriesKeyArray]);
        resp?.data.data?.brands?.map((val) => {
          let key = { [val]: false };

          brandKeyArray.push(key);
        });
        setbrandKey([...brandKeyArray]);
        resp?.data.data?.model?.map((val) => {
          let key = { [val]: false };
          modalKeyArray.push(key);
        });
        setModalKey([...modalKeyArray]);
        setFilterList(resp?.data?.data);
      })
      .catch((err) => console.log(err));
  };
  //search Filter Api
  const SearchFilterCheck = () => {
    console.log(
      condition,
      chipset,
      brand,
      series,
      model,
      "GetAllProductFilter"
    );
    ExportApi.GetAllProductFilter(condition, chipset, brand, series, model)
      .then((resp) => {
        console.log("datanew11");
        setproductData(resp.data.Data);
      })
      .catch((err) => console.log(err));
  };

  //chipset Filter
  const ChipsetFilter = (e, i) => {
    setSelectCheckBox(false);
    const { name, checked } = e.target;
    let index = chipset.indexOf(name);
    if (checked || index === -1) {
      chipset.push(name);
      chipsetKey[i][name] = checked;
      setchipset([...chipset]);
      console.log(chipset, chipsetKey, "chipsetKey12");
      // SearchFilterCheck();
      filtersearch();
    } else {
      chipsetKey[i][name] = checked;
      chipset.splice(index, 1);
      setchipset([...chipset]);
      console.log(chipset, chipsetKey, "chipsetKey13");
      //SearchFilterCheck();
      filtersearch();
    }
  };

  // condition Filter
  const conditionKeyFilter = (e, i) => {
    setSelectCheckBox(false);
    const { name, checked, min } = e.target;
    let number = parseInt(min);
    let index = condition.indexOf(number);
    let index1 = condition1.indexOf(name);
    if (checked || index === -1 || index1 === -1) {
      condition.push(number);
      condition1.push(name);
      conditionKey[i][name] = checked;
      setcondition([...condition]);
      setcondition1([...condition1]);
      // setTimeout(() => {
      //SearchFilterCheck();
      // });
      filtersearch();
    } else {
      conditionKey[i][name] = checked;
      condition.splice(index, 1);
      setcondition([...condition]);
      condition1.splice(index1, 1);
      setcondition1([...condition1]);
      // setTimeout(() => {
      //SearchFilterCheck();
      // });
      filtersearch();
    }
  };

  //modal filter
  const modalFilter = (e, i) => {
    console.log(e, i, "modalFilter12");
    setSelectCheckBox(false);
    const { name, checked } = e.target;
    let index = chipset.indexOf(name);
    if (checked) {
      model.push(name);
      modalKey[i][name] = checked;
      setmodel([...model]);
      // setTimeout(() => {
      // SearchFilterCheck();
      //});
      filtersearch();
    } else {
      modalKey[i][name] = checked;
      model.splice(index, 1);
      setmodel([...model]);
      //setTimeout(() => {
      //SearchFilterCheck();
      //});
      filtersearch();
    }
  };

  //series filter
  const seriesFilter = (e, i) => {
    setSelectCheckBox(false);
    const { name, checked } = e.target;
    let index = series.indexOf(name);
    if (checked) {
      series.push(name);
      seriesKey[i][name] = checked;
      setseries([...series]);
      //setTimeout(() => {
      //SearchFilterCheck();
      //});
      filtersearch();
    } else {
      seriesKey[i][name] = checked;
      series.splice(index, 1);
      setseries([...series]);
      //setTimeout(() => {
      //SearchFilterCheck();
      //});
      filtersearch();
    }
  };

  //Brand Filter
  const BrandtFilter = (e, i) => {
    setSelectCheckBox(false);
    const { name, checked } = e.target;
    let index = brand.indexOf(name);
    if (checked) {
      brand.push(name);
      brandKey[i][name] = checked;
      setbrand([...brand]);
      //setTimeout(() => {
      //SearchFilterCheck();
      //});
      filtersearch();
    } else {
      brandKey[i][name] = checked;
      brand.splice(index, 1);
      setbrand([...brand]);
      //setTimeout(() => {
      //SearchFilterCheck();
      //});
      filtersearch();
    }
  };
  // show more chipset Result
  const handlechipsetshowmore = (data) => {
    if (data == "More") {
      setChipsetShow(true);
    } else {
      setChipsetShow(false);
    }
  };

  // show more brand result
  const handlebrandshowmore = (data) => {
    if (data == "More") {
      setBrandShow(true);
    } else {
      setBrandShow(false);
    }
  };

  //show more series result
  const handleSeriesshowmore = (data) => {
    if (data == "More") {
      setSeriesShow(true);
    } else {
      setSeriesShow(false);
    }
  };

  //show more model result
  const handlemodelshowmore = (data) => {
    if (data == "More") {
      setModelShow(true);
    } else {
      setModelShow(false);
    }
  };

  // clear All Filter
  const clearAllFilters = () => {
    HandleGetFilterListData();
    localStorage.setItem("clearFilter", "true");
    localStorage.setItem("pageNumber", "0");
    setPageNumber(0);
    setPageOffset(0);
    //localStorage.removeItem("pageNumber");
    // setPageNumber(0);
    // handlePageClick({ selected: 1 });
    //localStorage.setItem("pageNumber", 0);
    // if (
    //   model?.length > 0 ||
    //   series?.length > 0 ||
    //   brand?.length > 0 ||
    //   chipset?.length > 0 ||
    //   condition?.length > 0
    // ) {
    setmodel([]);
    setseries([]);
    setbrand([]);
    setchipset([]);
    setcondition([]);
    setcondition1([]);
    setSortColumn(""); 
    setSortOrder("");
    if (userType == "user") {
      HandleGetProductData(userId);
      HandleGetFilterListData(userId);
    } else if (userType == "admin") {
      HandleGetProductData(userId);
      HandleGetFilterListData(userId);
      HandleGetProductData1();
    } else {
      //HandleGetProductData1();
      filtersearch();
    }
    // setLoading(0);
    // } else {
    //   setLoading(1);
    // }
  };

  //Condition Filter Close
  const conditionKeyFilterClose = (i, name) => {
    let index = -1;
    for (let i = 0; i < conditionKey.length; i++) {
      if (Object.keys(conditionKey[i])[0] == name) {
        index = i;
      }
    }
    conditionKey[index][name] = false;
    condition1.splice(i, 1);
    condition.splice(i, 1);
    setcondition1([...condition1]);
    setcondition([...condition]);
    setPageNumber(0);
    setPageOffset(0);
    //setTimeout(() => {
    //SearchFilterCheck();
    //});
    filtersearch();
  };

  //Chipset Filter Close
  const ChipsetFilterClose = (id, name) => {
    let index = -1;
    for (let i = 0; i < chipsetKey.length; i++) {
      if (Object.keys(chipsetKey[i])[0] == name) {
        index = i;
      }
    }

    chipsetKey[index][name] = false;
    chipset.splice(id, 1);
    setchipset([...chipset]);
    //setTimeout(() => {
    //SearchFilterCheck();
    //});
    setPageNumber(0);
    setPageOffset(0);
    filtersearch();
  };

  //Brand Filter Close
  const brandtFilterClose = (i, name) => {
    let index = -1;
    for (let i = 0; i < brandKey.length; i++) {
      if (Object.keys(brandKey[i])[0] == name) {
        index = i;
      }
    }
    brandKey[index][name] = false;
    brand.splice(i, 1);
    setbrand([...brand]);
    //setTimeout(() => {
    //SearchFilterCheck();
    //});
    setPageNumber(0);
    setPageOffset(0);
    filtersearch();
  };

  //Series Filter Close
  const seriesFilterCsole = (i, name) => {
    let index = -1;
    for (let i = 0; i < seriesKey.length; i++) {
      if (Object.keys(seriesKey[i])[0] == name) {
        index = i;
      }
    }
    seriesKey[index][name] = false;
    series.splice(i, 1);
    setseries([...series]);
    //setTimeout(() => {
    //SearchFilterCheck();
    //});
    setPageNumber(0);
    setPageOffset(0);
    filtersearch();
  };

  //modal Filter Close
  const modalFilterCsole = (i, name) => {
    let index = -1;
    for (let i = 0; i < modalKey.length; i++) {
      if (Object.keys(modalKey[i])[0] == name) {
        index = i;
      }
    }
    modalKey[index][name] = false;
    model.splice(i, 1);
    setmodel([...model]);

    //SearchFilterCheck();

    setPageNumber(0);
    setPageOffset(0);
    filtersearch();
  };

  const handleCreateBatch = () => {
    if (selectedData?.length > 1) {
      setShowButtonLoader(true);
      ExportApi.createBatch(selectedData, userId, "sell")
        .then((resp) => {
          if (resp.data.message == "Batch Created Successfully") {
            toast.success(resp.data.message);
            // setTimeout(() => {
            //   setUncheckedBox(true);
            // }, 1000);
            setSelectCheckBox(false);
            setShowButtonLoader(false);
            HandleGetProductData(userId);
            setTimeout(() => {
              setSelectedData([]);
              setSelectCheckBox(false);
            }, 1000);
          } else {
            toast.error(resp.data.message);
            // setTimeout(() => {
            //   setUncheckedBox(true);
            // }, 1000);
            setSelectedData([]);
            setSelectCheckBox(false);
            setShowButtonLoader(false);
            HandleGetProductData(userId);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      //toast.error("Please Select the Product First");
      toast.error("You must select at least 2 products.");

      setShowButtonLoader(false);
    }
  };

  const handleHideBatch = () => {
    setHideBatchStatus(!hideBatchStatus);
    if (hideBatchStatus) {
      if (userType == "user") {
        HandleGetProductData(userId);
        HandleGetFilterListData();
      } else if (userType == "admin") {
        HandleGetProductData(userId);
        HandleGetFilterListData();
      } else {
        // userdata;
        HandleGetProductData1();
        HandleGetFilterListData();
      }
    } else {
      let data = productData?.filter((item) => item.batchStatus != true);
      //console.log(data,"data>1005");
      console.log("datanew12");
      setproductData([...data]);
    }
  };

  useEffect(() => {
    let userdata;
    if (localStorage.getItem("tokenuser")) {
      userdata = JSON.parse(localStorage.getItem("tokenuser"));
      setUserId(userdata.id);
      setUserType(userdata.role);
      HandleGetProductData(userdata.id);
      HandleGetFilterListData();
    } else if (localStorage.getItem("admin")) {
      userdata = JSON.parse(localStorage.getItem("admin"));
      setUserId(userdata.id);
      setUserType(userdata.role);
      HandleGetProductData(userdata.id);
      HandleGetFilterListData();
    } else {
      HandleGetProductData1();
      HandleGetFilterListData();
    }

    if (localStorage.getItem("tokenuser")) {
      var checkBatchStatusId = JSON.parse(
        localStorage.getItem("tokenuser")
      )?.id;
    } else {
      var checkBatchStatusId = JSON.parse(localStorage.getItem("admin"))?.id;
    }
    ExportApi.checkBatchStatus(checkBatchStatusId)
      .then((resp) => {
        if (resp?.data?.statusCode == 200) {
          setAlreadyCreatedBatch(resp?.data.data.batchStatus);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [localStorage.getItem("tokenuser"), localStorage.getItem("admin")]);

  window.addEventListener("Login", () => {
    if (localStorage.getItem("tokenuser")) {
      HandleGetProductData(JSON.parse(localStorage.getItem("tokenuser")).id);
    } else {
      HandleGetProductData(JSON.parse(localStorage.getItem("admin")).id);
    }
  });

  window.addEventListener("Loginout", () => {
    //setTimeout(() => {
    console.log("logout");
    //});
  });

  console.log(selectedData, "selectedData454");

  const updateBatchButtonClick = () => {
    console.log("updateBatchButtonClick12");

    console.log(batchData?.length, "batchDatal");
    console.log(batchData, "batchDatal");
    console.log(selectedData?.length, "selectdata11");

    if (selectedData?.length === 0) {
      toast.error("Please select a product first");
    } else if (selectedData?.length === 1 && batchData?.length <= 1) {
      toast.error("Select two or more products");
    }
    //  (selectedData?.length >= 2)
    else {
      console.log(selectedData.length, "selectedData.length1");

      const uniqueSelectedIds = new Set(selectedData);
      console.log(uniqueSelectedIds, "uniqueSelectedIds12");

      // Use a Map to ensure uniqueness based on _id
      const uniqueProductMap = new Map();
      productData.forEach((item) => {
        if (uniqueSelectedIds.has(item._id)) {
          uniqueProductMap.set(item._id, item); // Keeps only one entry per _id
        }
      });

      console.log(uniqueProductMap, "uniqueProductMap123");

      // Convert the Map values to an array
      const resultArray = Array.from(uniqueProductMap.values());

      let finalArray = resultArray.map((item) => item?._id);
      const uniqueBatchids = Array.from(
        new Set(batchData?.map((item) => item.productId._id))
      );
      console.log(uniqueBatchids, "uniqueBatchids123");

      let nonSelected = uniqueBatchids.filter((id) => !finalArray.includes(id));
      console.log(finalArray, "finalArray12");

      let finalArrayToSend;
      if (nonSelected?.length <= 1) {
        finalArrayToSend = [...finalArray, ...nonSelected];
      } else {
        finalArrayToSend = [...finalArray];
      }
      console.log(nonSelected, "unique");
      // return;
      handleUpdateBatch(true, finalArrayToSend);
      setTimeout(() => {
        setUncheckedBox(true);
        setSelectedData([]);
        setSelectCheckBox(false);
        console.log("1111");
      }, 1000);
    }
    // else {
    //   console.log("222");
    //   setTimeout(() => {
    //     setUncheckedBox(true);
    //   }, 1000);
    //   toast.error("Please select a Product First");
    // }
  };

  const [askListOrderBook, setAskListOrderBook] = useState();
  const [bidListOrderBook, setBidListOrderBook] = useState();

  const showOrderBook = (product_id) => {
    setLoader(true);
    ExportApi.showOrderBook(product_id)
      .then((resp) => {
        console.log(resp?.data);
        if (resp?.data.statusCode == 200) {
          setLoader(false);
          setAskListOrderBook(resp?.data.data.askList);
          setBidListOrderBook(resp?.data.data.bidList);
        }
        // console.log(resp?.data.data.askList);
      })
      .catch((err) => {
        setLoader(false);
        console.log(err);
      });
  };

  const handlePageClick = ({ selected }) => {
    console.log(selected, "handlePageClick");
    setPageNumber(selected);
    setPageOffset(selected);
    setSelectCheckBox(false);
    setSelectedData([]);

    setMainloader(true);
    const data = {
      condition: [],
      chipset: [],
      brand: [],
      series: [],
      model: [],
      currentPage: selected + 1, 
      searchString: searchString,
      sortColumn,      
      sortOrder: sortOrder,

    };

    ExportApi.buysellfilter(data)
      .then((resp) => {
        setMainloader(false);
        console.log(resp, "resppp12");

        const totalpages = resp?.data?.Total;
        if (resp?.data?.Data.length > 0) {
          let products = resp?.data?.Data;
          console.log(products, "products11");

          setTotalPages(totalpages);

          let BatchStatus = products.filter(
            (item) => item?.batchStatus === true
          );
          console.log(BatchStatus, "BatchStatus000");
          if (BatchStatus?.length > 1) {
            setBatchData(BatchStatus);
            let BCount = BatchStatus.length / 2;
            setBatchCount(BCount === 0.5 ? BCount : Math.ceil(BCount) - 1);
          } else {
            setBatchData([]);
          }

          setproductData(products);

          setLoading(1);
        } else {
          setproductData([]);
          setLoading(2);
        }
      })
      .catch((err) => {
        console.log(err);
        setMainloader(false);
      });
  };

  const filtersearch = () => {
    // setPageNumber(0);
    setMainloader(true);
    //const pageno = localStorage.getItem("pageNumber") || 1;
    // const pageno = localStorage.getItem("pageNumber") === "0" ? 0 : 1;
    // console.log(pageno, "pageno11");

    const data = {
      condition: condition1,
      chipset: chipset,
      brand: brand,
      series: series,
      model: model,
      currentPage: pageNumber + 1,
      searchString: searchString,  
      sortColumn,      
      sortOrder: sortOrder,
    };

    ExportApi.buysellfilter(data)
      .then((resp) => {
        console.log(resp, "HandleGetProductData3");
        const data = resp?.data.Data;
        const totalpages = resp?.data.Total;
        //console.log(totalpages, "totalpages>90");

        setMainloader(false);
        //console.log(resp, "GetAllProduct>123");
        if (data?.length > 0) {
          setTotalPages(totalpages);
          console.log("datanew13");
          setproductData(data);
          setproductData1(data);
          setLoading(1);
        } else {
          console.log("setLoading(2)1098");
          setLoading(2);
          setproductData(data);
        }
      })
      .catch((err) => {
        console.log(err);
        setMainloader(false);
      });
  };

  const handleFilterSet = () => {
    filtersearch();

    const selectedCondition = [];
    const selectedChipset = [];
    const selectedBrand = [];
    const selectedSeries = [];
    const selectedModel = [];

    document
      .querySelectorAll('input[name="condition"]:checked')
      .forEach((checkbox) => {
        selectedCondition.push(checkbox.name);
      });

    document
      .querySelectorAll('input[name="chipset"]:checked')
      .forEach((checkbox) => {
        selectedChipset.push(checkbox.name);
      });

    document
      .querySelectorAll('input[name="brand"]:checked')
      .forEach((checkbox) => {
        selectedBrand.push(checkbox.name);
      });

    document
      .querySelectorAll('input[name="series"]:checked')
      .forEach((checkbox) => {
        selectedSeries.push(checkbox.name);
      });

    document
      .querySelectorAll('input[name="model"]:checked')
      .forEach((checkbox) => {
        selectedModel.push(checkbox.name);
      });

    // console.log('Selected Condition:', selectedCondition);
    // console.log('Selected Chipset:', selectedChipset);
    // console.log('Selected Brand:', selectedBrand);
    // console.log('Selected Series:', selectedSeries);
    // console.log('Selected Model:', selectedModel);
  };

  // console.log(pageNumber, "pageNumber22")

  // console.log("pageNumber11", pageNumber);

  // console.log("totalPages12", totalPages);

  console.log(productData, "productData321");
  console.log(showbatch, showaskbatch, "showbatch3211");

  const handleSort = (column) => {
  console.log(column,"column>>>321");
    const newSortOrder = (sortColumn === column && sortOrder === 'asc') ? 'desc' : 'asc';
    setSortColumn(column);
    setSortOrder(newSortOrder);
    fetchSortedData(column, newSortOrder);
  };
  const fetchSortedData = async (column, order) => {
    const data = {
      condition: [],
      chipset: [],
      brand: [],   
      series: [],  
      model: [],
      currentPage: pageNumber + 1,  
      searchString: searchString,   
      sortColumn: column,       
      sortOrder: order,
    };
  
    try {
      setLoading(true); 
      const resp = await ExportApi.buysellfilter(data);

      console.log(resp,"resp123");
      const sortdataall = resp?.data?.Data
      const totalpages = resp?.data?.Total;
      setTotalPages(totalpages);
      setproductData(sortdataall);
    } catch (error) {
      console.error('Error fetching sorted data:', error);
      setLoading(false);
    } 
  };
  
  console.log(productData,"productData321");
   


  return (
    <div>
      <Header
      //hideData={hideData}
      />
      <UserBuybaner />
      <Container>
        <Row className="buy-sell-part">
          <Col lg={12} md={12}>
            <div className="bg-dark p-3 d-md-flex align-items-center justify-content-between rounded mt-3">
              <div className="d-flex align-items-center justify-content-between search-box">
                <h4 className="mb-0 text-white me-3 fw-bold text-nowrap">
                  Buy/Sell
                </h4>
                <Form.Control
                  type="search"
                  placeholder="Search GPU"
                  onChange={(e) => handleSearch(e.target.value)}
                />
              </div>
              <div>
                {alreadyCreatedBatch ? (
                  <Button
                    className="bg-light text-black border-0 mb-2 mb-xl-0 mx-2 text-capitalize"
                    onClick={updateBatchButtonClick}
                  >
                    {showButtonLoader ? "Please Wait..." : "Batch "}
                    <ReactTooltip
                      className="custom-tooltip_buy-sell"
                      anchorId="app-batch-buying-tab"
                      place="bottom"
                      content=<div
                        dangerouslySetInnerHTML={{ __html: htmlString }}
                      />
                      style={{ textTransform: "none" }}
                    />
                    <svg
                      id="app-batch-buying-tab"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-question-circle-fill ms-1"
                      viewBox="0 0 16 16"
                    >
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.496 6.033h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286a.237.237 0 0 0 .241.247zm2.325 6.443c.61 0 1.029-.394 1.029-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94 0 .533.425.927 1.01.927z" />
                    </svg>
                  </Button>
                ) : (
                  <Button
                    className="bg-light text-black border-0 mb-2 mb-xl-0 mx-2 text-capitalize"
                    onClick={handleCreateBatch}
                  >
                    {showButtonLoader ? "Please Wait..." : "Batch"}
                    <ReactTooltip
                      className="custom-tooltip_buy-sell"
                      anchorId="app-batch-buying-tab"
                      place="bottom"
                      content=<div
                        dangerouslySetInnerHTML={{ __html: htmlString }}
                      />
                      style={{ textTransform: "none" }}
                    />
                    <svg
                      id="app-batch-buying-tab"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-question-circle-fill ms-1"
                      viewBox="0 0 16 16"
                    >
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.496 6.033h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286a.237.237 0 0 0 .241.247zm2.325 6.443c.61 0 1.029-.394 1.029-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94 0 .533.425.927 1.01.927z" />
                    </svg>
                  </Button>
                )}
                {hideBatchStatus ? (
                  <Button
                    className="bg-success text-white border-0 mb-2 mb-xl-0 mx-2 text-capitalize"
                    onClick={handleHideBatch}
                  >
                    Hide Batch
                  </Button>
                ) : (
                  <Button
                    className="bg-light text-black border-0 mb-2 mb-xl-0 mx-2 text-capitalize"
                    onClick={handleHideBatch}
                  >
                    Hide Batch
                  </Button>
                )}
                {bidStatus ? (
                  <Button
                    className="bg-success text-white border-0 mb-2 mb-xl-0 text-capitalize"
                    onClick={() => handleViewBid()}
                  >
                    view your bids
                  </Button>
                ) : (
                  <Button
                    className="bg-light text-black border-0 mb-2 mb-xl-0 text-capitalize"
                    onClick={() => handleViewBid()}
                  >
                    view your bids
                  </Button>
                )}
                {askStatus ? (
                  <Button
                    className="bg-success text-white border-0 mb-2 mb-xl-0 mx-2 text-capitalize"
                    onClick={handleViewAsk}
                  >
                    view your asks
                  </Button>
                ) : (
                  <Button
                    className="bg-light text-black border-0 mb-2 mb-xl-0 mx-2 text-capitalize"
                    onClick={handleViewAsk}
                  >
                    view your asks
                  </Button>
                )}

                <Button
                  className="bg-light text-black border-0 mb-2 mb-xl-0 px-5 text-capitalize"
                  onClick={() => handlefilterModal()}
                >
                  filter
                </Button>
              </div>
            </div>
          </Col>
          <Col lg={12} className="text-start mt-5"></Col>
        </Row>
        {/* {loading == 0 ? (
          <>
            <div className="loader-icon" style={{ marginBlock: "80px" }}>
              <i className="fa-solid fa-spinner fa-spin-pulse"></i>
            </div>

          </>
        ) : (
          <> */}
        <div
          //className="text-center text-sm-start"
          className="text-center text-sm-start d-flex flex-wrap gap-2"
        >
          <Button
            className="rounded-0 bg-white border text-dark px-5 py-2 fw-bold"
            onClick={() => clearAllFilters()}
          >
            Clear all filters
          </Button>
          {conditionKey.map((val, i) => {
            let key = condition1[i];
            let data = conditionKey.filter((item) => item[key] == true);
            return (
              <>
                {data.length != 0 ? (
                  <span
                    key={i}
                    //className="bg-custom-light border px-3 py-2 text-center ms-2 fw-500 fs-6 d-inline-block"
                    className={`bg-custom-light border px-3 py-2 text-center fw-500 fs-6  ${
                      !showMore.showLess ? "custom-childspan" : ""
                    }`}
                  >
                    {condition1[i]}{" "}
                    <Button
                      className="bg-none border-0 py-0 text-dark"
                      onClick={() => conditionKeyFilterClose(i, condition1[i])}
                    >
                      X
                    </Button>
                  </span>
                ) : null}
              </>
            );
          })}
          {chipsetKey.map((val, i) => {
            let key = chipset[i];
            let data = chipsetKey.filter((item) => item[key] == true);
            return (
              <>
                {data.length != 0 ? (
                  <>
                    <span
                      key={i}
                      //className="bg-custom-light border px-3 py-2 text-center ms-2 fw-500 fs-6 d-inline-block"
                      className={`bg-custom-light border px-3 py-2 text-center fw-500 fs-6  ${
                        !showMore.showLess ? "custom-childspan" : ""
                      }`}
                    >
                      {chipset[i]}
                      <Button
                        className="bg-none border-0 py-0 text-dark"
                        onClick={() => ChipsetFilterClose(i, chipset[i])}
                      >
                        X
                      </Button>
                    </span>
                  </>
                ) : (
                  ""
                )}
              </>
            );
          })}
          {brandKey?.map((val, i) => {
            let key = brand[i];
            let data = brandKey.filter((item) => item[key] == true);
            return (
              <>
                {data.length != 0 ? (
                  <span
                    key={i}
                    //className="bg-custom-light border px-3 py-2 text-center ms-2 fw-500 fs-6 d-inline-block"
                    className={`bg-custom-light border px-3 py-2 text-center fw-500 fs-6  ${
                      !showMore.showLess ? "custom-childspan" : ""
                    }`}
                  >
                    {brand[i]}{" "}
                    <Button
                      className="bg-none border-0 py-0 text-dark"
                      onClick={() => brandtFilterClose(i, brand[i])}
                    >
                      X
                    </Button>
                  </span>
                ) : null}
              </>
            );
          })}
          {seriesKey.map((val, i) => {
            let key = series[i];
            let data = seriesKey.filter((item) => item[key] == true);
            return (
              <>
                {data.length != 0 ? (
                  <span
                    key={i}
                    //className="bg-custom-light border px-3 py-2 text-center ms-2 fw-500 fs-6 d-inline-block"
                    className={`bg-custom-light border px-3 py-2 text-center fw-500 fs-6  ${
                      !showMore.showLess ? "custom-childspan" : ""
                    }`}
                  >
                    {series[i]}{" "}
                    <Button
                      className="bg-none border-0 py-0 text-dark"
                      onClick={() => seriesFilterCsole(i, series[i])}
                    >
                      X
                    </Button>
                  </span>
                ) : null}
              </>
            );
          })}
          {modalKey.map((val, i) => {
            let key = model[i];
            let data = modalKey.filter((item) => item[key] == true);
            return (
              <>
                {data?.length != 0 ? (
                  <span
                    key={i}
                    //className="bg-custom-light border px-3 py-2 text-center ms-2 fw-500 fs-6 d-inline-block"
                    className={`bg-custom-light border px-3 py-2 text-center fw-500 fs-6  ${
                      !showMore.showLess ? "custom-childspan" : ""
                    }`}
                  >
                    {console.log(model[i], "model[i]>>>>>>>>>123")}
                    {model[i]}
                    <Button
                      className="bg-none border-0 py-0 text-dark"
                      onClick={() => modalFilterCsole(i, model[i])}
                    >
                      X
                    </Button>
                  </span>
                ) : null}
              </>
            );
          })}
          {showMore.showMore && (
            <button
              className="btn btn-link"
              onClick={() =>
                setShowMore((prev) => ({
                  ...prev,
                  showMore: false,
                  showLess: true,
                }))
              }
            >
              Show More
            </button>
          )}
          {showMore.showLess && (
            <button
              className="btn btn-link"
              onClick={() =>
                setShowMore((prev) => ({
                  ...prev,
                  showLess: false,
                  showMore: true,
                }))
              }
            >
              Show Less
            </button>
          )}
        </div>
        <div className="table-responsive custom-table mb-5">
          <div className="gap-2 h-100">
            <Table
              bordered
              responsive
              striped
              className="align-middle graphic-table by-sell-table "
            >
              <thead>
                <tr>
                  {/* <th></th> */}
                  {showbatch || showaskbatch ? <th></th> : ""}

                  <th className="text-start d-flex align-items-center gap-2">
                   
                    <Form.Check
                      className="d-inline buysellp"
                      type="checkbox"
                      checked={selectCheckBox}
                      onClick={(e) => handleSelectAll(e)}
                    />
                    <label>Select All</label>
                  </th>
                  {/* <th onClick={() => sortHere("chipset")}>
                    Chipset
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="currentColor"
                      className="bi bi-chevron-expand"
                      viewBox="0 0 16 16"
                    >
                      <path
                        filerule="evenodd"
                        d="M3.646 9.146a.5.5 0 0 1 .708 0L8 12.793l3.646-3.647a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 0-.708zm0-2.292a.5.5 0 0 0 .708 0L8 3.207l3.646 3.647a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 0 0 0 .708z"
                      />
                    </svg>
                  </th>
                  <th onClick={() => sortHere("brand")}>
                    Brand
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="currentColor"
                      className="bi bi-chevron-expand"
                      viewBox="0 0 16 16"
                    >
                      <path
                        filerule="evenodd"
                        d="M3.646 9.146a.5.5 0 0 1 .708 0L8 12.793l3.646-3.647a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 0-.708zm0-2.292a.5.5 0 0 0 .708 0L8 3.207l3.646 3.647a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 0 0 0 .708z"
                      />
                    </svg>
                  </th>
                  <th onClick={() => sortHere("series")}>
                    Series
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="currentColor"
                      className="bi bi-chevron-expand"
                      viewBox="0 0 16 16"
                    >
                      <path
                        filerule="evenodd"
                        d="M3.646 9.146a.5.5 0 0 1 .708 0L8 12.793l3.646-3.647a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 0-.708zm0-2.292a.5.5 0 0 0 .708 0L8 3.207l3.646 3.647a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 0 0 0 .708z"
                      />
                    </svg>
                  </th>
                  <th onClick={() => sortHere("model")}>
                    Model
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="currentColor"
                      className="bi bi-chevron-expand"
                      viewBox="0 0 16 16"
                    >
                      <path
                        filerule="evenodd"
                        d="M3.646 9.146a.5.5 0 0 1 .708 0L8 12.793l3.646-3.647a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 0-.708zm0-2.292a.5.5 0 0 0 .708 0L8 3.207l3.646 3.647a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 0 0 0 .708z"
                      />
                    </svg>
                  </th>
                  <th
                    onClick={() =>
                      sortHereLowestAsk("new_retail_website_price", "bid")
                    }
                  >
                    Highest Bid
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="currentColor"
                      className="bi bi-chevron-expand"
                      viewBox="0 0 16 16"
                    >
                      <path
                        filerule="evenodd"
                        d="M3.646 9.146a.5.5 0 0 1 .708 0L8 12.793l3.646-3.647a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 0-.708zm0-2.292a.5.5 0 0 0 .708 0L8 3.207l3.646 3.647a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 0 0 0 .708z"
                      />
                    </svg>
                  </th>
                  <th
                    onClick={() =>
                      sortHereLowestAsk("new_retail_market_price", "ask")
                    }
                  >
                    Lowest Ask
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="currentColor"
                      className="bi bi-chevron-expand"
                      viewBox="0 0 16 16"
                    >
                      <path
                        filerule="evenodd"
                        d="M3.646 9.146a.5.5 0 0 1 .708 0L8 12.793l3.646-3.647a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 0-.708zm0-2.292a.5.5 0 0 0 .708 0L8 3.207l3.646 3.647a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 0 0 0 .708z"
                      />
                    </svg>
                  </th> */}
                   <th onClick={() => handleSort("chipset")}>
        Chipset
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          fill="currentColor"
          className={`bi bi-chevron-${sortColumn === 'chipset' ? (sortOrder === 'asc' ? 'up' : 'down') : 'expand'}`}
          viewBox="0 0 16 16"
        >
          <path
            fillRule="evenodd"
            d="M3.646 9.146a.5.5 0 0 1 .708 0L8 12.793l3.646-3.647a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 0-.708zm0-2.292a.5.5 0 0 0 .708 0L8 3.207l3.646 3.647a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 0 0 0 .708z"
          />
        </svg>
      </th>
      <th onClick={() => handleSort("brand")}>
        Brand
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          fill="currentColor"
          className={`bi bi-chevron-${sortColumn === 'brand' ? (sortOrder === 'asc' ? 'up' : 'down') : 'expand'}`}
          viewBox="0 0 16 16"
        >
          <path
            fillRule="evenodd"
            d="M3.646 9.146a.5.5 0 0 1 .708 0L8 12.793l3.646-3.647a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 0-.708zm0-2.292a.5.5 0 0 0 .708 0L8 3.207l3.646 3.647a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 0 0 0 .708z"
          />
        </svg>
      </th>
      <th onClick={() => handleSort("series")}>
        Series
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          fill="currentColor"
          className={`bi bi-chevron-${sortColumn === 'series' ? (sortOrder === 'asc' ? 'up' : 'down') : 'expand'}`}
          viewBox="0 0 16 16"
        >
          <path
            fillRule="evenodd"
            d="M3.646 9.146a.5.5 0 0 1 .708 0L8 12.793l3.646-3.647a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 0-.708zm0-2.292a.5.5 0 0 0 .708 0L8 3.207l3.646 3.647a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 0 0 0 .708z"
          />
        </svg>
      </th>
      <th onClick={() => handleSort("model")}>
        Model
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          fill="currentColor"
          className={`bi bi-chevron-${sortColumn === 'model' ? (sortOrder === 'asc' ? 'up' : 'down') : 'expand'}`}
          viewBox="0 0 16 16"
        >
          <path
            fillRule="evenodd"
            d="M3.646 9.146a.5.5 0 0 1 .708 0L8 12.793l3.646-3.647a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 0-.708zm0-2.292a.5.5 0 0 0 .708 0L8 3.207l3.646 3.647a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 0 0 0 .708z"
          />
        </svg>
      </th>
      <th onClick={() => handleSort("highest_bid")}>
        Highest Bid
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          fill="currentColor"
          className={`bi bi-chevron-${sortColumn === 'highest_bid' ? (sortOrder === 'asc' ? 'up' : 'down') : 'expand'}`}
          viewBox="0 0 16 16"
        >
          <path
            fillRule="evenodd"
            d="M3.646 9.146a.5.5 0 0 1 .708 0L8 12.793l3.646-3.647a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 0-.708zm0-2.292a.5.5 0 0 0 .708 0L8 3.207l3.646 3.647a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 0 0 0 .708z"
          />
        </svg>
      </th>
      <th onClick={() => handleSort("lowest_ask")}>
        Lowest Ask
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          fill="currentColor"
          className={`bi bi-chevron-${sortColumn === 'lowest_ask' ? (sortOrder === 'asc' ? 'up' : 'down') : 'expand'}`}
          viewBox="0 0 16 16"
        >
          <path
            fillRule="evenodd"
            d="M3.646 9.146a.5.5 0 0 1 .708 0L8 12.793l3.646-3.647a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 0-.708zm0-2.292a.5.5 0 0 0 .708 0L8 3.207l3.646 3.647a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 0 0 0 .708z"
          />
        </svg>
      </th>
                  <th>Your Ask/Bid</th>
                </tr>
              </thead>

              <tbody>
                {mainloader == true ? (
                  <>
                    <div
                      style={{
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        className="loader-icon custom-loader"
                        style={{ marginBlock: "80px" }}
                      >
                        <i className="fa-solid fa-spinner fa-spin-pulse"></i>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    {/* {console.log(productData, "productData11")} */}
                    {productData?.length > 0 ? (
                      productData.map((val, i) => {
                        // console.log(batchData?.length, "batchData?.length11");
                        return (
                          <tr key={i}>
                            {val?.batchStatus && batchData?.length > 1 ? (
                              i === 0 ? (
                                <td
                                  rowSpan={batchData?.length}
                                  className="fst-part-cont"
                                >
                                  <div className="bg-dark lft-bar text-white mr-1 d-flex align-items-center">
                                    <p className="vertical-text">BATCH</p>
                                  </div>
                                </td>
                              ) : (
                                ""
                              )
                            ) : showbatch || showaskbatch ? (
                              <>
                                <td></td>
                              </>
                            ) : (
                              ""
                            )}
                            <td>
                              <span
                                className="float-end"
                                style={{ cursor: "default" }}
                              >
                                {
                                  // val?.type == 1
                                  //   ? "New"
                                  //   :
                                  val?.type == 2 ? "New" : "Used"
                                }
                              </span>
                              <div className="d-flex align-items-end justify-content-between">
                                {/* {selectCheckBox ?  <Form.Check
                                  className="d-inline buysellp"
                                  type="checkbox"
                                  checked={selectCheckBox}
                                  onClick={(e) =>
                                    handleCheck(e.target.checked, val?._id)
                                  }
                                />
                                : 
                                <Form.Check
                                  className="d-inline buysellp"
                                  type="checkbox"
                                  onClick={(e) =>
                                    handleCheck(e.target.checked, val?._id)
                                  }
                                 
                                />
                           } */}
                                <Form.Check
                                  className="d-inline buysellp"
                                  type="checkbox"
                                  checked={
                                    selectedData?.includes(val?._id) || false
                                  }
                                  onClick={(e) =>
                                    handleCheck(e.target.checked, val?._id)
                                  }
                                />
                                &nbsp;
                                <div  className="buysellimg"> 
                                <img
                                  src={
                                    val?.image?.[0] ||
                                    val?.productId?.image?.[0]
                                  }
                                  style={{ 
                                    cursor: "pointer",
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "cover",
                                    objectPosition: "top"
                                   }}
                                  // onClick={() =>
                                  //   navigate("/product/" + val?._id)
                                  // }
                                  onClick={() =>
                                    window.open(`/product/${val._id}`, "_blank")
                                  }
                                />
                                </div>
                                {descriptionindex == i ? (
                                  <Button
                                    className="bg-none border-0"
                                    onClick={() => setdescriptionindex()}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="16"
                                      height="16"
                                      fill="#0000ff"
                                      className="bi bi-chevron-down"
                                      viewBox="0 0 16 16"
                                    >
                                      <path
                                        filerule="evenodd"
                                        d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                                      />
                                    </svg>
                                  </Button>
                                ) : (
                                  <Button
                                    className="bg-none border-0"
                                    onClick={() => setdescriptionindex(i)}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="16"
                                      height="16"
                                      fill="#0000ff"
                                      className="bi bi-chevron-down"
                                      viewBox="0 0 16 16"
                                    >
                                      <path
                                        filerule="evenodd"
                                        d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                                      />
                                    </svg>
                                  </Button>
                                )}
                              </div>
                              <OutsideClickHandler
                                onOutsideClick={(e) => {
                                  if (!e.target.closest(".modal_custom_")) {
                                    setdescriptionindex();
                                    setdescriptionindex1();
                                  }
                                }}
                              >
                                <div className="tool_tip_custom custom">
                                  {descriptionindex == i ? (
                                    <div
                                      className="modal_custom_"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                      }}
                                    >
                                      <div className="inner_customModal_one">
                                        <h5
                                          dangerouslySetInnerHTML={{
                                            __html: `${
                                              val?.description
                                                ? val?.description.substring(
                                                    0,
                                                    351
                                                  )
                                                : "No product description"
                                            }${
                                              val?.description?.length > 351
                                                ? "..."
                                                : ""
                                            }`,
                                          }}
                                        ></h5>
                                        {val?.description?.length > 351 ? (
                                          <a
                                            onClick={() =>
                                              navigate(`/product/${val._id}`)
                                            }
                                          >
                                            See more
                                          </a>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </div>
                                  ) : null}
                                </div>
                              </OutsideClickHandler>
                            </td>

                            <td>
                              <div style={{ cursor: "default" }}>
                                {val?.chipset}
                              </div>
                            </td>
                            <td>
                              <div style={{ cursor: "default" }}>
                                {val?.brand}
                              </div>
                            </td>
                            <td>
                              <div style={{ cursor: "default" }}>
                                {val?.series}
                              </div>
                            </td>

                            <td>
                              <div
                                className="text-breakdown"
                                style={{ cursor: "default" }}
                                title={val.model}
                              >
                                {val?.model}
                              </div>
                            </td>

                            <td>
                              {val?.type != 1 ? (
                                <Button
                                  className="border bg-danger red-btn"
                                  // onClick={() =>
                                  //   navigate("/bidask/" + val?._id + "/sell")
                                  // }
                                  onClick={() =>
                                    window.open(
                                      "/bidask/" + val?._id + "/sell",
                                      "_blank"
                                    )
                                  }
                                  disabled={val.highest_bid > 0 ? false : true}
                                >
                                  Sell Now -
                                  {val.highest_bid > 0
                                    ? "$" + val?.highest_bid
                                    : "TBD"}
                                </Button>
                              ) : (
                                <></>
                              )}
                            </td>

                            <td className="d-flex align-items-center py-5">
                              <div>
                                {val?.type == 1 ? (
                                  <Button
                                    className="border bg-success green-btn"
                                    // onClick={() =>
                                    //   navigate("/bidask/" + val?._id + "/buy")
                                    // }
                                    onClick={() =>
                                      window.open(
                                        "/bidask/" + val?._id + "/buy",
                                        "_blank"
                                      )
                                    }
                                    disabled={
                                      val.new_retail_website_price > 0
                                        ? false
                                        : true
                                    }
                                  >
                                    Buy Now - ${val?.new_retail_website_price}
                                  </Button>
                                ) : (
                                  <>
                                    <Button
                                      className="border bg-success green-btn"
                                      // onClick={() =>
                                      //   navigate("/bidask/" + val?._id + "/buy")
                                      // }
                                      onClick={() =>
                                        window.open(
                                          "/bidask/" + val?._id + "/buy",
                                          "_blank"
                                        )
                                      }
                                      disabled={
                                        val.lowest_ask > 0 ? false : true
                                      }
                                    >
                                      Buy Now -
                                      {/* {val?.lowest_ask > 0
                                        ? "$" + val?.lowest_ask
                                        : "TBDs"} */}
                                      {askStatus
                                        ? val?.askAmount > 0
                                          ? "$" + val?.askAmount
                                          : "TBDs"
                                        : val?.lowest_ask > 0
                                        ? "$" + val?.lowest_ask
                                        : "TBDs"}
                                      {/* {bidStatus
                                        ? val?.lowest_ask > 0
                                          ? "$" + val?.lowest_ask
                                          : "TBDs"
                                        : val?.askAmount > 0
                                        ? "$" + val?.askAmount
                                        : "TBDs"} */}
                                    </Button>

                                    {descriptionindex1 == i ? (
                                      <Button
                                        className="bg-none border-0"
                                        onClick={() => {
                                          setdescriptionindex1();
                                          setAskListOrderBook();
                                          setBidListOrderBook();
                                        }}
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="16"
                                          fill="#0000ff"
                                          className="bi bi-chevron-down"
                                          viewBox="0 0 16 16"
                                        >
                                          <path
                                            filerule="evenodd"
                                            d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                                          />
                                        </svg>
                                      </Button>
                                    ) : (
                                      <Button
                                        className="bg-none border-0 arrow-btn-new"
                                        onClick={() => {
                                          showOrderBook(val?._id);
                                          setdescriptionindex1(i);
                                        }}
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="16"
                                          fill="#0000ff"
                                          className="bi bi-chevron-down"
                                          viewBox="0 0 16 16"
                                        >
                                          <path
                                            filerule="evenodd"
                                            d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                                          />
                                        </svg>
                                      </Button>
                                    )}
                                  </>
                                )}
                                {descriptionindex1 == i ? (
                                  <div
                                    class="inner_customModal custom-tab-mode"
                                    id="bid_ask_model"
                                  >
                                    <tr>
                                      {loader ? (
                                        <div
                                          className="loader-icon loader-icon1"
                                          style={{
                                            height: "50px",
                                            width: "90px",
                                          }}
                                        >
                                          <i
                                            className="fa-solid fa-spinner fa-spin-pulse"
                                            style={{ marginTop: "80px" }}
                                          ></i>
                                        </div>
                                      ) : (
                                        <>
                                          <td>
                                            <div class="table-responsive-sm">
                                              <table class="table table-striped table-bordered table-hover">
                                                <thead>
                                                  <tr>
                                                    <th>Qty</th>
                                                    <th>Bid</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  {bidListOrderBook?.length >
                                                  0 ? (
                                                    bidListOrderBook?.map(
                                                      (item) => (
                                                        <tr>
                                                          <td>
                                                            {item?.quantity}
                                                          </td>
                                                          <td>
                                                            ${item?.bidAmount}
                                                          </td>
                                                        </tr>
                                                      )
                                                    )
                                                  ) : (
                                                    <td colspan="2">No Bids</td>
                                                  )}
                                                </tbody>
                                              </table>
                                            </div>
                                          </td>
                                          <td>
                                            <div class="table-responsive-sm">
                                              <table class="table table-striped table-bordered table-hover">
                                                <thead>
                                                  <tr>
                                                    <th>Qty</th>
                                                    <th>Ask</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  {askListOrderBook?.length >
                                                  0 ? (
                                                    askListOrderBook?.map(
                                                      (item) => (
                                                        <tr>
                                                          <td>
                                                            {item?.quantity}
                                                          </td>
                                                          <td>
                                                            {" "}
                                                            ${item?.askAmount}
                                                          </td>
                                                        </tr>
                                                      )
                                                    )
                                                  ) : (
                                                    <td colspan="2">No Asks</td>
                                                  )}
                                                </tbody>
                                              </table>
                                            </div>
                                          </td>
                                        </>
                                      )}
                                    </tr>
                                  </div>
                                ) : null}
                              </div>
                            </td>
                            <td>
                              {val?.type === 1 ? (
                                "Retail Only"
                              ) : val?.bidPrice == null ||
                                val?.askPrice == null ? (
                                <Button
                                  className="border bg-none text-dark border-dark"
                                  // onClick={() => {
                                  //   navigate("/bidask/" + val?._id);
                                  // }}
                                  onClick={() =>
                                    window.open("/bidask/" + val?._id, "_blank")
                                  }
                                >
                                  Place Bid/Ask
                                </Button>
                              ) : (
                                <>
                                  <Button className="border bg-danger">
                                    {"$" + val?.bidPrice}
                                  </Button>
                                  <Button className="border bg-success">
                                    {"$" + val?.askPrice}
                                  </Button>
                                </>
                              )}
                            </td>
                            {/* <td>{val?._id}</td>
                            <td> myid</td> */}
                          </tr>
                        );
                      })
                    ) : (
                      <span>No Product Found</span>
                    )}
                  </>
                )}
              </tbody>
            </Table>
          </div>
        </div>
        <div className="pagination_box">
          {/* {totalPages >= 0  ? ( */}

          {totalPages > 1 && (
            <ReactPaginate
              previousLabel={"< Previous"}
              nextLabel={"Next >"}
              breakLabel={"..."}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              pageCount={totalPages}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageClick}
              containerClassName={"pagination"}
              activeClassName={"active"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              forcePage={pageOffset}
            />
          )}
        </div>
        {/* </>
        )} */}

        {/*         
        // : (
        //   <span>
        //     <b>No Data Found</b>
        //   </span>
        // ) */}
      </Container>
      {/* filter modal */}

      <Modal
        show={showFilterModel}
        onHide={() => setShowFilterModel(false)}
        backdrop={true}
        // backdrop={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>Product Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body className="scrollable-modal-body">
          <div className="mt-sm-2 mt-3 border mb-sm-2">
            <div className="sidebar-accordian p-3">
              <div>
                <span>
                  <b>Condition</b>
                </span>
                {FilterList?.condition
                  ?.filter((item) => item != 1)
                  ?.map((val, i) => {
                    return (
                      <Form.Group className="mb-2" controlId={val} key={i}>
                        <Form.Check
                          type="checkbox"
                          checked={
                            localStorage.getItem("condition")
                              ? JSON.parse(localStorage.getItem("condition"))[
                                  i
                                ][val == 2 ? "New" : "Used"]
                              : conditionKey[i][val == 2 ? "New" : "Used"]
                          }
                          onChange={(e) => conditionKeyFilter(e, i)}
                          name={val == 2 ? "New" : "Used"}
                          min={val}
                          label={val == 2 ? "New" : "Used"}
                        />
                      </Form.Group>
                    );
                  })}
                {conditionKey?.length > 5 ? (
                  <Button className="bg-none mb-3 border-0 p-0 text-dark">
                    <span className="d-inline me-1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-plus-square-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm6.5 4.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3a.5.5 0 0 1 1 0z" />
                      </svg>
                    </span>
                    Show More
                  </Button>
                ) : null}
              </div>

              {/* Chipset */}
              <div>
                <span>
                  <b>Chipset</b>
                </span>
                {chipsetShow
                  ? FilterList?.chipset?.map((val, i) => {
                      return (
                        <Form.Group className="mb-2" controlId={val} key={i}>
                          <Form.Check
                            type="checkbox"
                            checked={
                              localStorage.getItem("chipset")
                                ? JSON.parse(localStorage.getItem("chipset"))[
                                    i
                                  ][val]
                                : chipsetKey[i][val]
                            }
                            onChange={(e) => ChipsetFilter(e, i)}
                            name={val}
                            label={val}
                          />
                        </Form.Group>
                      );
                    })
                  : FilterList?.chipset?.map((val, i) => {
                      return (
                        <>
                          {i < 3 ? (
                            <Form.Group
                              className="mb-2"
                              controlId={val}
                              key={i}
                            >
                              <Form.Check
                                type="checkbox"
                                checked={
                                  localStorage.getItem("chipset")
                                    ? JSON.parse(
                                        localStorage.getItem("chipset")
                                      )[i][val]
                                    : chipsetKey[i][val]
                                }
                                onChange={(e) => ChipsetFilter(e, i)}
                                name={val}
                                label={val}
                              />
                            </Form.Group>
                          ) : null}
                        </>
                      );
                    })}
                {chipsetShow ? (
                  FilterList?.chipset?.length > 3 ? (
                    <Button
                      className="bg-none mb-3 border-0 p-0 text-dark"
                      onClick={() => handlechipsetshowmore("Less")}
                    >
                      <span className="d-inline me-1">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-plus-square-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm6.5 4.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3a.5.5 0 0 1 1 0z" />
                        </svg>
                      </span>{" "}
                      Show Less
                    </Button>
                  ) : null
                ) : FilterList?.chipset?.length > 3 ? (
                  <Button
                    className="bg-none mb-3 border-0 p-0 text-dark"
                    onClick={() => handlechipsetshowmore("More")}
                  >
                    <span className="d-inline me-1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-plus-square-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm6.5 4.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3a.5.5 0 0 1 1 0z" />
                      </svg>
                    </span>{" "}
                    Show More
                  </Button>
                ) : null}
              </div>

              {/* Brand */}
              <div>
                <span>
                  <b>Brand</b>
                </span>
                {brandShow
                  ? FilterList?.brands?.map((val, i) => {
                      return (
                        <Form.Group className="mb-2" controlId={val} key={i}>
                          <Form.Check
                            type="checkbox"
                            checked={
                              localStorage.getItem("brand")
                                ? JSON.parse(localStorage.getItem("brand"))[i][
                                    val
                                  ]
                                : brandKey[i][val]
                            }
                            onChange={(e) => BrandtFilter(e, i)}
                            name={val}
                            label={val}
                          />
                        </Form.Group>
                      );
                    })
                  : FilterList?.brands?.map((val, i) => {
                      return (
                        <>
                          {i < 3 ? (
                            <Form.Group
                              className="mb-2"
                              controlId={val}
                              key={i}
                            >
                              <Form.Check
                                type="checkbox"
                                checked={
                                  localStorage.getItem("brand")
                                    ? JSON.parse(localStorage.getItem("brand"))[
                                        i
                                      ][val]
                                    : brandKey[i][val]
                                }
                                onChange={(e) => BrandtFilter(e, i)}
                                name={val}
                                label={val}
                              />
                            </Form.Group>
                          ) : null}
                        </>
                      );
                    })}
                {brandShow ? (
                  FilterList?.brands?.length > 3 ? (
                    <Button
                      className="bg-none mb-3 border-0 p-0 text-dark"
                      onClick={() => handlebrandshowmore("Less")}
                    >
                      <span className="d-inline me-1">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-plus-square-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm2.5 7.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1 0-1" />
                        </svg>
                      </span>{" "}
                      Show Less
                    </Button>
                  ) : null
                ) : FilterList?.brands?.length > 3 ? (
                  <Button
                    className="bg-none mb-3 border-0 p-0 text-dark"
                    onClick={() => handlebrandshowmore("More")}
                  >
                    <span className="d-inline me-1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-plus-square-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm6.5 4.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3a.5.5 0 0 1 1 0z" />
                      </svg>
                    </span>{" "}
                    Show More
                  </Button>
                ) : null}
              </div>

              {/* Series */}
              <div>
                <span>
                  <b>Series</b>
                </span>
                {seriesShow ? (
                  <div style={{ maxHeight: "400px", overflowY: "auto" }}>
                    {FilterList?.series?.map((val, i) => {
                      return (
                        <Form.Group className="mb-2" controlId={val} key={i}>
                          <Form.Check
                            type="checkbox"
                            checked={
                              localStorage.getItem("series")
                                ? JSON.parse(localStorage.getItem("series"))[i][
                                    val
                                  ]
                                : seriesKey[i][val]
                            }
                            onChange={(e) => seriesFilter(e, i)}
                            name={val}
                            label={val}
                          />
                        </Form.Group>
                      );
                    })}
                  </div>
                ) : (
                  <div>
                    {FilterList?.series?.map((val, i) => {
                      return i < 6 ? (
                        <Form.Group className="mb-2" controlId={val} key={i}>
                          <Form.Check
                            type="checkbox"
                            checked={
                              localStorage.getItem("series")
                                ? JSON.parse(localStorage.getItem("series"))[i][
                                    val
                                  ]
                                : seriesKey[i][val]
                            }
                            onChange={(e) => seriesFilter(e, i)}
                            name={val}
                            label={val}
                          />
                        </Form.Group>
                      ) : null;
                    })}
                  </div>
                )}
                {seriesShow ? (
                  FilterList?.series?.length > 10 ? (
                    <Button
                      className="bg-none mb-3 border-0 p-0 text-dark"
                      onClick={() => handleSeriesshowmore("Less")}
                    >
                      <span className="d-inline me-1">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-dash-square-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm2.5 7.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1 0-1" />
                        </svg>
                      </span>{" "}
                      Show Less
                    </Button>
                  ) : null
                ) : FilterList?.series?.length > 3 ? (
                  <Button
                    className="bg-none mb-3 border-0 p-0 text-dark"
                    onClick={() => handleSeriesshowmore("More")}
                  >
                    <span className="d-inline me-1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-plus-square-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm6.5 4.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3a.5.5 0 0 1 1 0z" />
                      </svg>
                    </span>{" "}
                    Show More
                  </Button>
                ) : null}
              </div>

              {/* Model */}
              <div>
                <span>
                  <b>Model</b>
                </span>
                {modelShow ? (
                  <div style={{ maxHeight: "400px", overflowY: "auto" }}>
                    {FilterList?.model?.map((val, i) => {
                      return (
                        <Form.Group className="mb-2" controlId={val} key={i}>
                          <Form.Check
                            type="checkbox"
                            checked={modalKey[i][val]}
                            onChange={(e) => modalFilter(e, i)}
                            name={val}
                            label={val}
                          />
                        </Form.Group>
                      );
                    })}
                  </div>
                ) : (
                  <div>
                    {FilterList?.model?.map((val, i) => {
                      return i < 6 ? (
                        <Form.Group className="mb-2" controlId={val} key={i}>
                          <Form.Check
                            type="checkbox"
                            checked={modalKey[i][val]}
                            onChange={(e) => modalFilter(e, i)}
                            name={val}
                            label={val}
                          />
                        </Form.Group>
                      ) : null;
                    })}
                  </div>
                )}
                {FilterList?.model?.length > 10 && (
                  <Button
                    className="bg-none mb-3 border-0 p-0 text-dark"
                    onClick={() => setModelShow(!modelShow)}
                  >
                    <span className="d-inline me-1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className={` ${
                          modelShow
                            ? "bi bi-dash-square-fill"
                            : "bi bi-plus-square-fill"
                        }`}
                        viewBox="0 0 16 16"
                      >
                        {modelShow ? (
                          <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm2.5 7.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1 0-1" />
                        ) : (
                          <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm6.5 4.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3a.5.5 0 0 1 1 0z" />
                        )}
                      </svg>
                    </span>
                    Show {modelShow ? "Less" : "More"}
                  </Button>
                )}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {/* <Button
            variant="secondary"
            onClick={() => {
              setShowFilterModel(false);
              handleSeriesshowmore("Less");
              setModelShow(false);
              setcondition1([]);
              setmodel([]);
              setseries([]);
              setbrand([]);
              setchipset([]);
              setcondition([]);
              clearAllFilters();
              HandleGetFilterListData();
              setPageOffset(0)
            }}
          >
            Close
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              setShowFilterModel(false);
              handleFilterSet();
            
            }}
          >
            Save Changes
          </Button> */}
        </Modal.Footer>
      </Modal>

      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        backdrop={true}
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Login</Modal.Title>
        </Modal.Header>
        <Modal.Body className="scrollable-modal-body">
          <Login modal={() => setShowModal(false)} />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default UserBuySell;
