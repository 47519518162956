// import logo from "./logo.svg";
import React, { useEffect, useState } from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import Home from "../src/pages/home";
import Categories from "./pages/categories";
import Product from "./pages/Product";
import Bidask from "../src/pages/bidask";
import BuySell from "./pages/BuySell";
import BuySellManagment from "./pages/BuySellManagment";
//import OrderView from "./pages/OrderView";
import Dashboard from "./pages/Dashboard";
import Footer from "./components/footer"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'ckeditor5/ckeditor5.css';
import useSessionManagement from './useSessionManagement';
import { checkSessionExpiry, updateActivityTime } from './sessionUtils';

function App() {
  // useEffect(() => {
  //   useSessionManagement();
  // }, [])
  // useEffect(() => {
  //   console.log("hit this uppppp")
  //   // Check session expiry on component mount
  //   checkSessionExpiry();

  //   // Update activity time on user interaction
  //   const handleActivity = () => {
  //       console.log("Activity detected");
  //     updateActivityTime();
  //   };

  //   // Event listeners for detecting user activity
  //   window.addEventListener('mousemove', handleActivity);
  //   window.addEventListener('keypress', handleActivity);
  //   window.addEventListener('click', handleActivity);

  //   // Periodic check for session expiry
  //   const sessionCheckInterval = setInterval(() => {
  //       console.log("hit this up sesssion check")
  //     checkSessionExpiry();
  //   }, 1000); // Check every 5 seconds (adjust as needed)

  //   // Cleanup event listeners and interval on component unmount
  //   return () => {
  //     window.removeEventListener('mousemove', handleActivity);
  //     window.removeEventListener('keypress', handleActivity);
  //     window.removeEventListener('click', handleActivity);
  //     clearInterval(sessionCheckInterval);
  //   };
  // }, []);
 

  return (
    <div className="App">
       <ToastContainer/>
      <Home />
      <Categories />
      <Product />
      <Bidask />
      <BuySell />
      <BuySellManagment />
      {/* <OrderView /> */}
      <Dashboard />
      <Footer />
    </div>
  );
}

export default App;
