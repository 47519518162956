import React, { useRef, useEffect, useState, useCallback } from "react";
import Footer from "../../components/footer";
import Header from "../../components/header";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./News.css";
import ExportApi from "../../api/ExportApi";
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import _ from "lodash";
import Pagination from "@mui/material/Pagination";

const filterHtmlContent = (html) => {
  return html.replace(/<[^>]*>/g, "").replace(/&nbsp;/g, "");
};

function News() {
  const [newsList, setNewsList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [clickCount, setClickCount] = useState(0);
  const itemsPerPage = 9;
  const [keyword, setKeyword] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [searchloading, setSearchLoading] = useState(false);
  const [searchPerformed, setSearchPerformed] = useState(false);
  const [totalnews, setTotalNews] = useState();


  const [totalPages, setTotalPages] = useState(0);

  const navigate = useNavigate();
  const searchRef = useRef(null);

  const fetchNewsList = async (page = 1) => {
    try {
      setLoading(true);
      const resp = await ExportApi.getAllnews(page);
      console.log(resp, "fetchNewsList12");
      const newsData = resp?.data?.data;


      setTotalPages(resp?.data?.totalPages); 
      setTotalNews(resp?.data?.totalNewsCount); 
      setLoading(false);
      setNewsList(newsData);
    } catch (error) {
      console.error("Error fetching news list:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchNewsList(currentPage); // Fetch news for the current page
  }, [currentPage]);

  const handlePageClick = (event, value) => {
    console.log(value, "selected123");
    setCurrentPage(value);
  };

  let startTime = new Date();
  const handleClick = async (newsItemId) => {
    console.log("hit this up");
    try {
      navigate(`/news/${newsItemId}`);

      const endTime = new Date();
      const timeDifference = endTime - startTime;
      const averageTime = timeDifference / (clickCount + 1); // Increment clickCount

      const averageTimeInMinutes = averageTime / 60000;
      console.log(averageTimeInMinutes, "averageTimeInMinutes");
      const averageTimeInSeconds = averageTimeInMinutes * 60;
      console.log(averageTimeInSeconds, "averageTimeInSeconds");

      const averageTimeRounded = averageTimeInSeconds.toFixed(2);
      const averageTimes = parseFloat(averageTimeRounded);
      const data = {
        averageTime: averageTimes,
        totalCount: clickCount + 1,
      };

      console.log(newsItemId, "newsItemId11");
      console.log(data, "data321");

      const response = await ExportApi.totalcount(newsItemId, data);

      if (response.ok) {
        console.log("Data posted successfully");
      } else {
        // Handle API errors
        console.error("Failed to post data:", response.statusText);
      }

      setClickCount((prevCount) => prevCount + 1); // Update clickCount
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const debounceSearch = useCallback(
    _.debounce(async (search) => {
      if (!search) {
        setSearchResults([]);
        setSearchPerformed(false);
        setSearchLoading(false);
        return;
      }
      setSearchLoading(true);
      setSearchPerformed(true);
      try {
        const response = await ExportApi.newssearch({ keyword: search });
        setSearchResults(response.data.data);
        setSearchLoading(false);
      } catch (error) {
        console.error("Error searching for news:", error);
        setSearchLoading(false);
      }
    }, 300),
    []
  );

  const handleSearch = (e) => {
    const search = e.trim();
    setKeyword(search); // Update keyword state for the search input
    debounceSearch(search);
  };

  const handleClear = () => {
    setKeyword("");
    setSearchResults([]);
    setSearchPerformed(false);
  };

  const handleNewClear = () => {
    //setKeyword('');
    setSearchResults([]);
    setSearchPerformed(false);
  };

  const handleClickOutside = (event) => {
    if (searchRef.current && !searchRef.current.contains(event.target)) {
      handleNewClear();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <Header />

      {loading ? (
        <div className="loader-icon" style={{ marginBlock: "80px" }}>
          <i className="fa-solid fa-spinner fa-spin-pulse"></i>
        </div>
      ) : (
        <>
          <div className="search-bar py-5" style={{ background: "#f9fafb" }}>
            <Container>
              <Row>
                <Col>
                  <div className="search">
                    <div className="input-group">
                      <span className="input-group-text" id="basic-addon1">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          className="bi bi-search"
                          viewBox="0 0 16 16"
                        >
                          <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"></path>
                        </svg>
                      </span>
                      <input
                        placeholder="Search For News"
                        autoComplete="false"
                        type="text"
                        className="form-control"
                        value={keyword}
                        onChange={(e) => handleSearch(e.target.value)}
                      />
                      {keyword && (
                        <div className="custom_svgCross">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="26"
                            height="26"
                            fill="#000"
                            className="bi bi-x"
                            viewBox="0 0 16 16"
                            onClick={handleClear}
                          >
                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                          </svg>
                        </div>
                      )}
                    </div>
                    {searchloading ? (
                      <>
                        <div className="custom-news w-100">
                          <div
                            className="loader-icon"
                            style={{ marginBlock: "20px" }}
                          >
                            <i className="fa-solid fa-spinner fa-spin"></i>
                          </div>
                        </div>
                      </>
                    ) : searchPerformed && searchResults.length === 0 ? ( // Show message only if search was performed
                      <div className="custom-news w-100">
                        <p style={{ textAlign: "center" }}>No results found</p>
                      </div>
                    ) : (
                      searchResults.length > 0 && (
                        <div className="custom-news">
                          {searchResults.map((newsItem) => (
                            <div className="custom-newsbar" key={newsItem?._id}>
                              <img
                                src={newsItem?.image}
                                alt={newsItem?.heading}
                                style={{
                                  width: "100px",
                                  height: "100px",
                                  borderRadius: "5px",
                                }}
                                onClick={() => handleClick(newsItem?._id)}
                              />
                              <div
                                onClick={() => handleClick(newsItem?._id)}
                                style={{ cursor: "pointer" }}
                              >
                                <h3>{newsItem?.heading}</h3>
                                <p style={{ wordBreak: "break-all" }}>
                                  {filterHtmlContent(
                                    newsItem?.description || ""
                                  )}
                                </p>
                              </div>
                            </div>
                          ))}
                        </div>
                      )
                    )}
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          <div className="News-section py-5">
            <Container>
              <Row>
                <>
                  {newsList?.length > 0 ? (
                    <>
                      <Row>
                        {newsList.map((newsItem, index) => (
                          <Col key={index} xs={12} md={4}>
                            <div
                              className="news-grid"
                              // onClick={() => navigate(`/news/${newsItem._id}`)}
                              onClick={() => handleClick(newsItem?._id)}
                            >
                              <img
                                className="w-100 mb-3"
                                style={{ width: "300px", height: "300px" }}
                                src={newsItem.image}
                                alt={newsItem.heading}
                              />
                              <h3 className="mb-3 text-dark fw-bold fs-5">
                                {newsItem.heading}
                              </h3>
                              <p
                                className="fs-6 fw-normal"
                                dangerouslySetInnerHTML={{
                                  __html: newsItem?.description.replace(
                                    /<h[^>]*>|<img[^>]*>|<\/?strong[^>]*>|<\/?a[^>]*>/g,
                                    ""
                                  ),
                                }}
                              />

                              {/* {newsItem.description}</p> */}
                            </div>
                          </Col>
                        ))}
                      </Row>

                      {totalnews > 9 ? (
                        <div className="d-flex align-items-center justify-content-end">
                          <Pagination
                            className="order_pagination"
                            count={totalPages}
                            color="primary"
                            hidePrevButton
                            hideNextButton
                            page={currentPage}
                            onChange={handlePageClick}
                          />
                        </div>
                      ) : (
                        <div></div>
                      )}
                    </>
                  ) : (
                    <div
                      className="loader-icon"
                      style={{ marginBlock: "80px" }}
                    >
                      <i className="fa-solid fa-spinner fa-spin-pulse"></i>
                    </div>
                  )}
                </>
              </Row>
            </Container>
          </div>
        </>
      )}

      <Footer />
    </>
  );
}

export default News;
